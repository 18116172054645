import project from "./folder/project";
import resource from "./folder/resource";
import job from "./folder/job";
import video from "./folder/video";
import audio from "./folder/audio";
import live from "./folder/live";
import user from "./folder/user";
import material from "./folder/material";
import materialcenter from "./folder/materialcenter";
import recognition from "./folder/recognition";
import template from "./folder/template";
import tag from "./folder/tag";
import threeTerminalProject from "./folder/threeTerminalProject";
import coarse from "./folder/coarse";

export default {
  project,
  resource,
  job,
  video,
  audio,
  live,
  material,
  materialcenter,
  user,
  recognition,
  template,
  tag,
  threeTerminalProject,
  coarse
};
