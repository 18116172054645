import Utils from '@/api/Utils'

/**
 * 普通上传
 */
export async function ksyunDirectUploadSdk (options, response) {
  const {
    keyUUID, file, callback, errorCb,
    isOneUpload = true, // 兼容序列上传， 为false时 不调用 callback

  } = options
  let {accessKeyId, secretAccessKey, relativePath, bucket, objectId, endpoint} = response

  Ks3.config.AK = accessKeyId
  Ks3.config.SK = secretAccessKey
  Ks3.config.region = 'BEIJING'

  //支持https 上传
  if (window.location.protocol === 'https:') {
    Ks3.config.protocol = 'https'
  } else {
    Ks3.config.protocol = 'http'
  }
  

  Ks3.config.bucket = bucket

  function generateCBArgs (progress, uploadStatus) {
    return {
      keyUUID,
      progress,
      filename: file.name,
      resourceId: objectId,
      key: relativePath,
      uploadFinish: uploadStatus,
      Location: (Utils.getCOSHost()  + '/' + relativePath).replace(/https:\/\/|http:\/\//, '')
    }
  }

  function progressFunction (args) {
    callback(generateCBArgs(Math.round((args.loaded / args.total) * 100), false))
  }

  return new Promise((resolve, reject)=>{
    Ks3.putObject({
      Bucket: bucket,
      Key: relativePath,
      File: file,
      ProgressListener: isOneUpload ? progressFunction : ()=>{} ,
      ACL: 'public-read',
      Sinature: ''
    }, function (err) {
      if (err) {
        console.log(err)
        reject(err)
      } else {
        isOneUpload && callback(generateCBArgs(100, true))
        resolve()
      }
    })
  })
}

export async function multipartUpload (options, response) {
  const {keyUUID, file, callback, errorCb} = options
  const {accessKeyId, secretAccessKey, relativePath, bucket, objectId, endpoint} = response
  Ks3.config.AK = accessKeyId
  Ks3.config.SK = secretAccessKey
  Ks3.config.region = 'BEIJING'
  Ks3.config.bucket = bucket

  function generateCBArgs (progress, uploadStatus) {
    return {
      keyUUID,
      progress,
      filename: file.name,
      resourceId: objectId,
      key: relativePath,
      uploadFinish: uploadStatus,
      Location: (Utils.getCOSHost()  + '/' + relativePath).replace(/https:\/\/|http:\/\//, '')
    }
  }

  ksyunMultipartUpload({
    Bucket: bucket,
    Key: relativePath,
    ACL: 'public-read',
    File: file,
    TotalSize: file.size,
    Signature: '',
  }, function (progress, status) {
    console.log('执行回调：', arguments)
    callback(generateCBArgs(progress, status))
  })
}

function ksyunMultipartUpload (params, cb) {
  var config
  var bucketName = params.Bucket || Ks3.config.bucket || ''
  var key = params.Key || params.File.name
  key = Ks3.encodeKey(key)
  var region = params.region || Ks3.config.region
  if (region) {
    Ks3.config.baseUrl = Ks3.ENDPOINT[region]
  }
  var file = params.File
  var totalSize = file.size //文件总大小
  var progressKey = getProgressKey(file.name, file.lastModified, bucketName, key)
  // 会根据文件大小,进行简单上传和分块上传
  var contentType = params.ContentType || ''
  // 分块上传
  async.auto({
      /**
       * 初始化配置文件,如果没有就新建一个
       */
      init: function (callback) {
        //重置暂停标识
        Ks3.config.stopFlag = false
        if (!localStorage[progressKey]) {
          configInit(file, progressKey, function (err) {
            callback(err)
          })
        } else {
          callback(null)
        }

      },
      show: ['init', function (callback) {
        console.log('  开始上传文件: ' + progressKey)
        config = JSON.parse(localStorage.getItem(progressKey))
        callback(null)
      }],
      /**
       * 获取uploadId,如果有就直接读取,没有就从服务器获取一个
       */
      getUploadId: ['init', function (callback) {
        config = JSON.parse(localStorage.getItem(progressKey))
        var uploadId = config['uploadId']

        if (!!uploadId) {
          callback(null, uploadId)
        } else {
          Ks3.multitpart_upload_init(params, function (err, uploadId) {
            if (err) {
              callback(err)
            } else {
              config['uploadId'] = uploadId
              localStorage.setItem(progressKey, JSON.stringify(config))
              callback(null, uploadId)
            }
          })
        }
      }],
      /**
       * 对文件进行上传
       * 上传后要把信息写到本地存储配置文件中
       * 如果都上传完了,就把相关本地存储信息删除
       * 并通知服务器,合并分块文件
       */
      upload: ['getUploadId', function (callback, result) {
        if (result.getUploadId) {
          var uploadId = result.getUploadId
          Ks3.config.currentUploadId = uploadId

          config = JSON.parse(localStorage.getItem(progressKey))
          var count = config['count']
          var index = config['index']
          var chunkSize = config['chunkSize']
          var currentRetries = config['retries']
          up()
        } else {
          callback({'msg': 'no uploadId'})
        }

        // 在报错的时候重试
        function retry (err) {
          console.log('upload ERROR:', err)
          if (currentRetries > Ks3.config.retries) {
            throw err
          } else {
            currentRetries = currentRetries + 1
            config['retries'] = currentRetries
            localStorage.setItem(progressKey, JSON.stringify(config))
            console.log('第 ' + currentRetries + ' 次重试')
            up()
          }
        }

        // 真正往服务端传递数据
        function up () {
          // console.log('正在上传 ', 'index: ' + index)
          var start = (index - 1) * chunkSize
          // 判断是否已经全部都传完了
          if (index <= count) {
            getFileContent(file, chunkSize, start, function (body) {
              delete params.File
              params.UploadId = uploadId
              params.PartNumber = index
              params.body = body
              params.type = contentType
              // console.log('正在上传第 ', index, ' 块,总共: ', +count + ' 块')
              cb(Math.floor((index / count) * 100), false, '') //执行 回调，刷新进度条
              try {
                Ks3.upload_part(params, function (err, partNumber, etag) {
                  if (err) {
                    if (err.status == 413 || err.status == 415) {
                      callback(err)
                    } else {
                      retry(err)
                    }
                  } else {
                    if (!Ks3.config.stopFlag) {
                      config['index'] = index
                      config['etags'][index] = etag
                      localStorage.setItem(progressKey, JSON.stringify(config))
                      index = index + 1
                      up()
                    } else {
                      callback({
                        msg: 'stop'
                      })
                    }
                  }
                })
              } catch (e) {
                retry(e)
              }
            })
          } else {
            // console.log('发送合并请求')
            delete params.File
            params.UploadId = uploadId
            params.body = generateCompleteXML(progressKey)

            Ks3.upload_complete(params, function (err, res) {
              if (err) throw err
              callback(err, res)
            })
          }
        }

      }]
    },
    function (err, results) {
      if (err) {
        if (err.msg !== 'stop') {
          console.error(err)
        } else {
          console.log(err)
        }
      } else {
        //删除配置
        localStorage.removeItem(progressKey)
      }
      if (cb) {
        cb(100, true, results.upload.CompleteMultipartUploadResult.Location)
      }
    })

}

/**
 * 把配置信息写到localStorage里,作为缓存
 * @param file 上传文件的句柄
 * @param progressKey  文件上传进度缓存在localStorage中的标记key
 * @param cb
 */
function configInit (file, progressKey, cb) {
  var fileSize = file.size
  var count = parseInt(fileSize / Ks3.config.chunkSize) + ((fileSize % Ks3.config.chunkSize == 0 ? 0 : 1))

  if (count == 0) {
    cb({
      msg: 'The file is empty.'
    })
  } else {
    let config = {
      name: file.name,
      size: fileSize,
      chunkSize: Ks3.config.chunkSize,
      count: count,
      index: 1,
      etags: {},
      retries: 0
    }
    localStorage.setItem(progressKey, JSON.stringify(config))
    if (cb) {
      cb(null)
    }
  }
}

/**
 * 计算用于记录上传任务进度的key
 * @param name
 * @param lastModified
 * @param bucket
 * @param key
 */
function getProgressKey (name, lastModified, bucket, key) {
  var result = name + '-' + lastModified + '-' + bucket + '-' + key
  return result
}

/**
 * 获取指定的文件部分内容
 */
function getFileContent (file, chunkSize, start, cb) {
  var start = start
  var bufferSize = file.size
  var index = start / chunkSize
  console.log('正在读取下一个块的文件内容 index:' + index)
  if (start + chunkSize > bufferSize) {
    chunkSize = bufferSize - start
  }
  console.log('分块大小:', chunkSize)

  if (file.slice) {
    var blob = file.slice(start, start + Ks3.config.chunkSize)
  } else if (file.webkitSlice) {
    var blob = file.webkitSlice(start, start + Ks3.config.chunkSize)
  } else if (file.mozSlice) {
    var blob = file.mozSlice(start, start + Ks3.config.chunkSize)
  } else {
    throw new Error('blob API doesn\'t work!')
  }

  var reader = new FileReader()
  reader.onload = function (e) {
    cb(e.target.result)
  }
  reader.readAsArrayBuffer(blob)
}

/**
 * 生成合并分块上传使用的xml
 */
function generateCompleteXML (progressKey) {
  var content = JSON.parse(localStorage.getItem(progressKey))
  var index = content.index
  var str = ''
  if (index > 0) {
    str = '<CompleteMultipartUpload>'
    for (var i = 1; i <= index; i++) {
      str += '<Part><PartNumber>' + i + '</PartNumber><ETag>' + content.etags[i] + '</ETag></Part>'
    }
    str += '</CompleteMultipartUpload>'
  }
  return str
}
