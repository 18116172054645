import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    mapDuplicateFootageArr: [],
    mapDuplicateCaptionArr: []
  },
  mutations: {
    updateMapDuplicateFootageArr (state, payload) {
      state.mapDuplicateFootageArr = payload
    },
    updateMapDuplicateCaptionArr (state, payload) {
      state.mapDuplicateCaptionArr = payload
    }
  }
}
