// 分片上传(闻歌)
// 断点续传只需要再执行实例的uploadItem()方法
import SparkMD5 from 'spark-md5'
import axios from 'axios'
/**
 * @param file 上传的文件
 * @param options 配置信息
 * @param options.userId 用户id
 * @param options.tenantId 租户id (闻歌提供)
 * @param options.progress 上传成功/上传进度回调
 * @param options.error 失败/错误回调
 * @param itemSize 分片大小, 默认2M
 */
export default class Uploader {
  // tenantId 租户id
  constructor (file, {url, userId, tenantId, progress, error, itemSize, key, uuid }) {
    this.url = url
    this.file = file
    this.userId = userId
    this.tenantId = tenantId
    this.progressCB = this.fn(progress) // 进度回调
    this.errCB = this.fn(error) // 失败回调
    this.itemSize = itemSize || 20480 // 分片大小, 默认2M
    this.index = 1
    this.key = key
    this.uuid = uuid
    if (this.file) {
      const { size } = this.file
      this.totalChunks = Math.ceil(size / this.itemSize) // 分片总数
      this.beforeUpload()
        .then(res => {
          // 判断文件是否已经上传，
          if (res.data.data[0] && res.data.data[0].skipUpload && res.data.data[0].mediaAssets.url) {
            this.progressCB({
              progress: 100,
              keyUUID: this.uuid,
              uploadFinish: true,
              filename: this.file.name,
              Location: res.data.data[0] ? res.data.data[0].mediaAssets.url.replace(/^http:\/\/|https:\/\//, '') : '',
              resourceId: '',
              key: '',
            })
          } else {
            this.uploadItem()
          }
        })
        .catch(err => {
          console.error('预上传 错误', err)
          this.errCB(err)
        })
    } else {
      console.error('请选择文件, uploader')
    }
  }
  fn (f) {
    const cb = () => {}
    return typeof f === 'function' ? f : cb
  }
  // 预上传
  async beforeUpload () {
    const { name } = this.file
    this.md5 = await this.calculate(this.file)
    return axios({
      methods: 'get',
      url: this.url + '/api/media/multipart/upload', // 这个可以用 `http://qhcloudhongqi.wengegroup.com:9116/api/media/multipart/upload`,
      params: {
        filename: name,
        identifier: this.md5,
        userId: this.userId,
        tenantId: this.tenantId,
        totalChunks: this.totalChunks
      }
    })
  }
  // 上传
  async uploadItem () {
    const filename = `${this.file.name}`
    const chunk = this.file.slice((this.index - 1) * this.itemSize, this.index * this.itemSize)
    let formData = new FormData()
    formData.append('file', chunk, filename)
    formData.append('chunkNumber', this.index)
    formData.append('totalSize', this.file.size)
    formData.append('identifier', this.md5)
    formData.append('userId', this.userId)
    formData.append('tenantId', this.tenantId)
    formData.append('fileName', filename)
    formData.append('totalChunks', this.totalChunks)
    formData.append('chunkSize', this.itemSize)
    formData.append('currentChunkSize', chunk.size)
    formData.append('source', 4)
    formData.append('isRelease', 0)
    formData.append('isShare', 0)
    formData.append('parentPath', '/')

    console.log('上传 md5', this.md5, this.index)
    axios({
      url: this.url + '_file/api/media/multipart/upload',
      method: 'post',
      data: formData
    }).then(res => {
      if (this.index < this.totalChunks) {
        console.log(this.index, '上传成功', parseInt((this.index / this.totalChunks) * 100), res.data.data)
        this.progressCB({
          progress: parseInt((this.index / this.totalChunks) * 100),
          keyUUID: this.uuid,
          uploadFinish: false,
          key: this.key,
          filename: this.file.name
        })
        this.index++
        this.uploadItem()
      } else { // 上传完成
        console.log('上传完成', res.data)
        this.progressCB({
          progress: 100,
          keyUUID: this.uuid,
          uploadFinish: true,
          filename: this.file.name,
          Location: res.data.data[0] ? res.data.data[0].mediaAssets.url.replace(/^http:\/\/|https:\/\//, '') : '',
          resourceId: '',
          key: '',
        })
      }
    }).catch(err => {
      console.error('分片上传失败', this.index, err)
      this.errCB(err)
    })
  }
  // 计算md5
  calculate (file) {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader()
      let blobSlice = File.prototype.mozSlice || File.prototype.webkitSlice || File.prototype.slice
      let chunkSize = 2097152
      // read in chunks of 2MB
      let chunks = Math.ceil(file.size / chunkSize)
      let currentChunk = 0
      let spark = new SparkMD5()

      fileReader.onload = function (e) {
        spark.appendBinary(e.target.result)
        currentChunk++
        if (currentChunk < chunks) {
          loadNext()
        } else {
          // console.info('computed hash', spark.end()) // compute hash
          resolve(spark.end())
        }
      }
      fileReader.onerror = reject

      function loadNext () {
        var start = currentChunk * chunkSize
        let end = start + chunkSize >= file.size ? file.size : start + chunkSize
        fileReader.readAsBinaryString(blobSlice.call(file, start, end))
      };

      loadNext()
    })
  }
}
