export default {
  namespaced: true,
  state: {
    trimModalClip: null,
    isShowTrimModal: false,
    folderPathArr: [],
    idMap: {}
  },
  getters: {
    parentFolderId (state) {
      return state.folderPathArr[state.folderPathArr.length - 1].id
    }
  },
  mutations: {
    updateShowTrimModal (state, payload) {
      state.isShowTrimModal = payload
    },
    updateTrimModalClip (state, payload) {
      state.trimModalClip = payload
    },
    updateFolderPathArr (state, payload = {}) {
      let { id, title, ancestorFolderPath = '' } = payload
      state.idMap[id] = title
      ancestorFolderPath = ancestorFolderPath.replace(/^\//, '')
      let idArr = []
      if (ancestorFolderPath) {
        idArr = ancestorFolderPath.split('/')
      }
      idArr.push(id)
      state.folderPathArr = idArr.map((item, index) => {
        return {
          id: item,
          title: state.idMap[item],
          ancestorFolderPath: idArr.slice(0, index).join('/')
        }
      })
    }
  }
}