import StringValue from './StringValue'
import { getQueryStringByName } from '@/utils'
import store from '@/store'
import i18n from '@/i18n'
import { Loading } from 'element-ui'

const defaultResolvingPower = 540 // 默认分辨率
// 记录时间线左侧宽度 同class leftStrackContainer定义的一致
const leftStrackContainerWidth = 140
const baseURL = 'www.baidu.com'
const RES_16_9_WIDTH = 960
const RES_16_9_HEIGHT = 540
const RES_6_7_HEIGHT = 630

// 一秒为25帧
const SYSTEM = 25
// 默认为10分钟  就是15000帧
const DEFAULT_TIME = 15000
// 微秒单位到秒的换算
const CONVERT = 1000000
// 最短时长1秒
const MINTIME = 1000000
// 字幕默认五秒长度
const CAPTIONDEFAULTLENGTH = 5 * 1000000
// 字幕动画默认2.5秒长度
const CAPTIONANIMATIONDEFAULTLENGTH = 0.5 * 1000000
// 贴纸动画默认2.5秒长度
const STICKERANIMATIONDEFAULTLENGTH = 0.5 * 1000000
// 动画默认5秒长度
const ANIMATIONDEFAULTLENGTH = 0.5 * 1000000
// 默认图片长度为一天 3600 * 24 * 100 * 1000000
const PICTUREDEFAULTLENGTH = 8640000 * 1000000
// 默认马赛克初始化强度为0.005
const DEFAULT_MOSAIC = 0.005
// 默认模糊初始化强度为5
const DEFAULT_GAUSSIAN_BLUR = 5
const DEFAULT_FEATHER_WIDTH = 0
const AUTOSAVETIME = 5 * 60 * 1000

const THUMBNAIL_SPACE = 1
// rulingCount 每次显示时间的间隔
//  tip 每个间隔代表的时间长度 rulingSpace 每个间隔的长度px
// tipSpace 每个间隔代表的帧数 25帧为一秒 时分秒还是60进制
const TimelineRules = [
  {index: 0, message: '1小时', rulingCount: 10, rulingSpace: 14, tip: '0.1小时', start: '01:00:00:00', tipSpace: 90000},
  {index: 1, message: '30分钟', rulingCount: 10, rulingSpace: 24, tip: '3分钟', start: '00:30:00:00', tipSpace: 4500},
  {index: 2, message: '10分钟', rulingCount: 10, rulingSpace: 18, tip: '1分钟', start: '00:10:00:00', tipSpace: 1500},
  {index: 3, message: '5分钟', rulingCount: 10, rulingSpace: 18, tip: '0.5分钟', start: '00:05:00:00', tipSpace: 750},
  {index: 4, message: '2分钟', rulingCount: 10, rulingSpace: 14, tip: '0.2分钟', start: '00:02:00:00', tipSpace: 300},
  {index: 5, message: '1分钟', rulingCount: 10, rulingSpace: 16, tip: '0.1分钟', start: '00:01:00:00', tipSpace: 150},
  {index: 6, message: '30秒', rulingCount: 10, rulingSpace: 9, tip: '3秒', start: '00:00:30:00', tipSpace: 75},
  {index: 7, message: '30秒', rulingCount: 10, rulingSpace: 15, tip: '3秒', start: '00:00:30:00', tipSpace: 75},
  {index: 8, message: '10秒', rulingCount: 10, rulingSpace: 13, tip: '1秒', start: '00:00:10:00', tipSpace: 25},
  {index: 9, message: '10秒', rulingCount: 10, rulingSpace: 21, tip: '1秒', start: '00:00:10:00', tipSpace: 25},
  {index: 10, message: '4秒', rulingCount: 10, rulingSpace: 11, tip: '0.4秒', start: '00:00:04:00', tipSpace: 10},
  {index: 11, message: '2秒', rulingCount: 10, rulingSpace: 11, tip: '0.2秒', start: '00:00:02:00', tipSpace: 5},
  {index: 12, message: '1秒', rulingCount: 5, rulingSpace: 22, tip: '0.2秒', start: '00:00:01:00', tipSpace: 5},
  {index: 13, message: '1秒', rulingCount: 5, rulingSpace: 36, tip: '0.2秒', start: '00:00:01:00', tipSpace: 5},
  {index: 14, message: '10帧', rulingCount: 10, rulingSpace: 18, tip: '一帧', start: '00:00:00:10', tipSpace: 1}
]
// 模板 画幅比
const SIZE_LIST = {
  1: '16:9',
  2: '1:1',
  4: '9:16',
  8: '4:3',
  16: '3:4',
  32: '18:9',
  64: '9:18',
  512: '21:9',
  1024: '9:21'
}
const TEMPLATE_PACKAGES = [
  'caption',
  'compoundcaption',
  'transition',
  'captionstyle',
  'captionrenderer',
  'captioncontext',
  'captioninanimation',
  'captionoutanimation',
  'captionanimation',
  'animatedsticker',
  'animatedstickerinanimation',
  'animatedstickeroutanimation',
  'animatedstickeranimation',
  'videofx',
  'resources'
]
const DEFAULT_RULER = 8
const NoPanel = 'none'
const MediaPanel = 'MediaEditPanel'
const LibrettoPanel = 'LibrettoPanel'
const CaptionPanel = 'CaptionPanel'
const AnimatedStickerPanel = 'AnimatedStickerEditPanel'
const MusicPanel = 'MusicPanel'
const TransitionPanel = 'TransitionEditPanel'
const FilterPanel = 'FilterEditPanel'
const MaskEditPanel = 'MaskEditPanel'
const RegulateEditPanel = 'RegulateEditPanel'
const ParticleParameterPanel = 'ParticleParameterPanel'
const ParticleGraffitlPanel = 'ParticleGraffitlPanel'
const ThemePanel = 'ThemePanel'
const TemplatePanel = 'TemplatePanel'
const RichCaptionPanel = 'RichCaptionPanel'
const RESOLUTION = {
  '9:16': { width: 540, height: 960 },
  '16:9': { width: 960, height: 540 },
  '9:21': { width: 540, height: 1260 },
  '21:9': { width: 1260, height: 540 },
  '18:9': { width: 1080, height: 540 },
  '9:18': { width: 540, height: 1080 },
  '3:4': { width: 540, height: 720 },
  '4:3': { width: 720, height: 540 },
  '1:1': { width: 540, height: 540 },
  '6:7': { width: 540, height: 630 }
}
let defaultFontFamily = ''
function setDefaultFontFamily (family) {
  defaultFontFamily = family
}
function getDefaultFontFamily () {
  if (store.state.material.defaultFontInstalling) {
    const loading = Loading.service({
      text: i18n.t('loading.font'),
      lock: true,
      background: 'rgba(0, 0, 0, 0.7)'
    })
    store.commit('layout/updateLoadingInstance', loading)
  }
  return defaultFontFamily
}
let defaultSubtitleStyle = ''
function setDefaultSubtitleStyle (styleDesc) {
  defaultSubtitleStyle = styleDesc
}

function getDefaultSubtitleStyle () {
  return defaultSubtitleStyle
}

let liveWindowMouseDragging = false
function setLiveWindowMouseDragging (dragging) {
  liveWindowMouseDragging = dragging
}
function getLiveWindowMouseDragging () {
  return liveWindowMouseDragging
}

let workflow = null
function getWorkflow() {
  return workflow
}
function setWorkflow(wf) {
  workflow = wf
}

function getProjectId () {
  const projectId = getQueryStringByName('projectId')
  return Number(projectId)
}

let projectUUID = 0
function setProjectUUID (id) {
  projectUUID = id
}
function getProjectUUID () {
  return projectUUID
}

let projectName = ''
function setProjectName (name) {
  projectName = name
}
function getProjectName () {
  return projectName
}

let multiProjectUUID = '' // 保存时使用的多端互通项目对应唯一 uuid， 用project/create 或者 project/info 接口响应中获取的
function setMultiProjectUUID (uuid) {
  multiProjectUUID = uuid
}
function getMultiProjectUUID () {
  return multiProjectUUID.toUpperCase()
}
let projectType = ''
function setProjectType (type) {
  projectType = type
}
function getProjectType () {
  return projectType
}

let minSdkVersionTemplateSupported = '2.18.0'
function setMinSdkVersionTemplateSupported (minSdkVersion) {
  minSdkVersionTemplateSupported = minSdkVersion
}
function getMinSdkVersionTemplateSupported () {
  return minSdkVersionTemplateSupported
}

let width = 0
let height = 0

function setWidth (id) {
  width = id
}

function getWidth () {
  return width
}

function setHeight (id) {
  height = id
}

function getHeight () {
  return height
}

let supportVideoFx = true
let supportAnimatedSticker = true
let supportMusic = true
let supportCaption = true
let supportVideoFxExtension = true
let supportVideoTransition = true
let supportTheme = true
let supportParticleVideoFx = true
let supportTemplate = true
let supportUseTemplate = true
let supportVideoTransitionExtension = true
let supportChangeSpeed = true
let supportClipSplit = true
let supportVideoColorAdjust = true
let supportCaptionStyleSetting = true
function setSupportVideoFx (support) {
  supportVideoFx = support
}
function isSupportVideoFx () {
  return supportVideoFx
}
function setSupportAnimatedSticker (support) {
  supportAnimatedSticker = support
}
function isSupportAnimatedSticker () {
  return supportAnimatedSticker
}
function setSupportMusic (support) {
  supportMusic = support
}
function isSupportMusic () {
  return supportMusic
}
function setSupportCaption (support) {
  supportCaption = support
}
function isSupportCaption () {
  return supportCaption
}
function setSupportVideoFxExtension (support) {
  supportVideoFxExtension = support
}
function isSupportVideoFxExtension () {
  return supportVideoFxExtension
}
function setSupportVideoTransition (support) {
  supportVideoTransition = support
}
function isSupportVideoTransition () {
  return supportVideoTransition
}
function setSupportTheme (support) {
  supportTheme = support
}
function isSupportTheme () {
  return supportTheme && process.env.PERM_LIST.feature_theme
}
function setSupportParticleVideoFx (support) {
  supportParticleVideoFx = support
}
function isSupportParticleVideoFx () {
  return supportParticleVideoFx
}
function setSupportTemplate (support) {
  supportTemplate = support
}
function isSupportTemplate () {
  return supportTemplate
}
function setSupportUseTemplate (support) {
  supportUseTemplate = support
}
function isSupportUseTemplate () {
  return supportUseTemplate
}
function setSupportVideoTransitionExtension (support) {
  supportVideoTransitionExtension = support
}
function isSupportVideoTransitionExtension () {
  return supportVideoTransitionExtension
}
function setSupportChangeSpeed (support) {
  supportChangeSpeed = support
}
function isSupportChangeSpeed () {
  return supportChangeSpeed
}
function setSupportClipSplit (support) {
  supportClipSplit = support
}
function isSupportClipSplit () {
  return supportClipSplit
}
function setSupportVideoColorAdjust (support) {
  supportVideoColorAdjust = support
}
function isSupportVideoColorAdjust () {
  return supportVideoColorAdjust
}
function setSupportCaptionStyleSetting (support) {
  supportCaptionStyleSetting = support
}
function isSupportCaptionStyleSetting () {
  return supportCaptionStyleSetting
}

// 每帧时间代表的px
function getTLTimeEveryPX () {
  const TLGrade = store.state.timeline.rulerRule
  let a = TimelineRules[TLGrade]
  return a.tipSpace / a.rulingSpace
}
// 每px代表的帧数
function getTLPXEveryTime () {
  const TLGrade = store.state.timeline.rulerRule
  let a = TimelineRules[TLGrade]
  return a.rulingSpace / a.tipSpace
}

function getDurationWidth () {
  return [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].reduce((prev, cur) => {
    prev[cur * CONVERT] = getTLPXEveryTime() * cur * 25
    return prev
  }, {})
}
// 基于 540P 比例 计算字幕字体大小
function computerFontSizeInResolvingPower (size, floor = true) {
  const resolvingPower = sessionStorage.resolvingPower || defaultResolvingPower
  return Math.round(floor ? size / (resolvingPower / 540) : size * (resolvingPower / 540))
}

function getNowTimeByPx (x, scrollX) {
  return parseInt(getTLTimeEveryPX() * (x - leftStrackContainerWidth + scrollX) / SYSTEM * CONVERT)
}
function getPxByNowTime (time, scrollX) {
  return time / CONVERT * SYSTEM / getTLTimeEveryPX() + leftStrackContainerWidth - scrollX
}
function getFSProjectFolder () {
  return FS_PROJECT
}
function getFSVideoFxFolder () {
  return FS_VIDEOFX
}
function getFSAnimatedStickerFolder () {
  return FS_ANIMATEDSTICKER
}
function getFSAnimatedStickerInAnimationFolder () {
  return '/animatedstickerinanimation'
}
function getFSAnimatedStickerOutAnimationFolder () {
  return '/animatedstickeroutanimation'
}
function getFSAnimatedStickerAnimationFolder () {
  return '/animatedstickeranimation'
}
function getFSCaptionStyleFolder () {
  return FS_CAPTIONSTYLE
}
function getFSCaptionContextFolder () {
  return '/captioncontext'
}
function getFSCaptionRendererFolder () {
  return '/captionrenderer'
}
function getFSCaptionInAnimationFolder () {
  return '/captioninanimation'
}
function getFSCaptionOutAnimationFolder () {
  return '/captionoutanimation'
}
function getFSCaptionAnimationFolder () {
  return '/captionanimation'
}
function getFSThemeFolder () {
  return FS_THEME
}
function getFSTransitionFolder () {
  return FS_TRANSITION
}
function getFSM3u8Folder () {
  return FS_M3U8
}
function getFSFontFolder () {
  return FS_FONT
}
function getFSCompoundFolder () {
  return FS_COMPOUNDCAPTION
}
function getModelFolder () {
  return '/model'
}
function getARSceneFolder () {
  return '/ARScene'
}
function getDatFolder () {
  return '/dat'
}
function getTemplateFolder () {
  return '/template'
}
// 其他资源
function getResourcesFolder () {
  return '/resources'
}
function getFSRecord () {
  return FS_RECORD
}
function getBackgroundImageFolder () {
  return '/backgroundimage'
}
function getInteractProjectFolder () {
  return '/interactproject'
}
function getProjectresourceFolder () {
  return '/projectresource'
}
let isLoadingProject = false;
function setIsLoadingProject(bool) {
  isLoadingProject = bool
}
function getIsLoadingProject(bool) {
  return isLoadingProject
}
const tempType = {
  0: 'video/image',
  1: 'video',
  2: 'image',
  3: 'audio'
}
const mediaType = {
  1: 'video',
  2: 'audio',
  3: 'image'
}
const MEDIA_TYPE = {
  VIDEO: 1,
  AUDIO: 2,
  IMAGE: 3
}
const MATERIAL_TRACK_DOM_HEIGHT = 30  // 视频、音频 之外的素材轨道
const AUDIO_TRACK_DOM_HEIGHT = 50 // 音频 轨道
const LYRICS_TRACK_DOM_HEIGHT = 40 // 唱词 / 特效 轨道
const VIDEO_TRACK_DOM_HEIGHT = 60  // 视频轨道
const TemplateCompound = 'templateCompound'
const FROMMEDIA = 'fromMedia'
const FROMMCAPTION = 'fromCaption'
const FROMTRACK = 'fromTrack'
const VIDEOTRACK = 'videoTrack'
const VIDEOCLIP = 'video'
const AUDIOTRACK = 'audioTrack'
const AUDIOCLIP = 'audio'
const CAPTIONTRACK = 'captionTrack'
const CAPTIONCLIP = 'caption'
const STICKERTRACK = 'stickerTrack'
const STICKERCLIP = 'sticker'
// 滤镜和粒子是package  特效时buildin
const TIMELINEVIDEOFXTYPEBUILDIN = 'builtin'
const TIMELINEVIDEOFXTYPPACKAGE = 'package'
const TIMELINEVIDEOFX = 'timelineVideoFx'
const TIMELINEVIDEOFXTRACK = 'timelineVideoFxTrack'
const TIMELINETHEME = 'timelineTheme'
const TRANSITION = 'transition'
const COMPOUNDCAPTIONTRACK = 'compoundCaptionTrack'
const COMPOUNDCAPTIONCLIP = 'compoundCaption'
const MUSICLYRICSTRACK = 'musicLyricsTrack'
const MUSICLYRICSCLIP = 'musicLyrics'
const BLANKLINE = 'blankLine'

// MonitorType
const MONITOR_TYPE_0 = 'MONITOR_TYPE_0' // 序列监听器
const MONITOR_TYPE_1 = 'MONITOR_TYPE_1' // 片段监听器
const MONITOR_TYPE_2 = 'MONITOR_TYPE_2' // H5-video监听器

// FS folder
const FS_PROJECT = '/project'
const FS_VIDEOFX = '/videofx'
const FS_ANIMATEDSTICKER = '/animatedsticker'
const FS_CAPTIONSTYLE = '/captionstyle'
const FS_THEME = '/theme'
const FS_TRANSITION = '/transition'
const FS_M3U8 = '/m3u8'
const FS_FONT = '/font'
const FS_COMPOUNDCAPTION = '/compoundcaption'
const FS_RECORD = '/record'
const AILibretto = 'AILibretto'

let hadFonts = []
// 后台已有的字体列表（stringValue）
function setHadFonts (fonts) {
  hadFonts = fonts
}
function getHadFonts () {
  return hadFonts
}

export default {
  MEDIA_TYPE,
  MATERIAL_TRACK_DOM_HEIGHT,
  AUDIO_TRACK_DOM_HEIGHT,
  LYRICS_TRACK_DOM_HEIGHT,
  VIDEO_TRACK_DOM_HEIGHT,
  ...StringValue,
  defaultResolvingPower,
  TEMPLATE_PACKAGES,
  MONITOR_TYPE_0,
  MONITOR_TYPE_1,
  MONITOR_TYPE_2,
  CONVERT,
  leftStrackContainerWidth,
  MINTIME,
  baseURL,
  RES_16_9_WIDTH,
  RES_16_9_HEIGHT,
  RES_6_7_HEIGHT,
  SYSTEM,
  DEFAULT_TIME,
  DEFAULT_MOSAIC,
  DEFAULT_GAUSSIAN_BLUR,
  DEFAULT_FEATHER_WIDTH,
  CAPTIONDEFAULTLENGTH,
  CAPTIONANIMATIONDEFAULTLENGTH,
  STICKERANIMATIONDEFAULTLENGTH,
  ANIMATIONDEFAULTLENGTH,
  TimelineRules,
  NoPanel,
  MediaPanel,
  LibrettoPanel,
  CaptionPanel,
  AnimatedStickerPanel,
  MusicPanel,
  TransitionPanel,
  FilterPanel,
  MaskEditPanel,
  RegulateEditPanel,
  ParticleParameterPanel,
  ParticleGraffitlPanel,
  ThemePanel,
  TemplatePanel,
  RichCaptionPanel,
  TemplateCompound,
  TIMELINETHEME,
  setIsLoadingProject,
  getIsLoadingProject,
  getDurationWidth,
  setDefaultFontFamily,
  getDefaultFontFamily,
  setDefaultSubtitleStyle,
  getDefaultSubtitleStyle,
  setHadFonts,
  getHadFonts,
  setLiveWindowMouseDragging,
  getLiveWindowMouseDragging,
  getProjectId,
  setProjectName,
  getProjectName,
  getMultiProjectUUID,
  setMultiProjectUUID,
  setProjectType,
  getProjectType,
  setProjectUUID,
  getProjectUUID,
  setMinSdkVersionTemplateSupported,
  getMinSdkVersionTemplateSupported,
  DEFAULT_RULER,
  setWidth,
  getWidth,
  setHeight,
  getHeight,
  setSupportVideoFx,
  isSupportVideoFx,
  setSupportAnimatedSticker,
  isSupportAnimatedSticker,
  setSupportMusic,
  isSupportMusic,
  setSupportCaption,
  isSupportCaption,
  setSupportVideoFxExtension,
  isSupportVideoFxExtension,
  setSupportVideoTransition,
  isSupportVideoTransition,
  setSupportTheme,
  isSupportTheme,
  setSupportParticleVideoFx,
  isSupportParticleVideoFx,
  setSupportTemplate,
  isSupportTemplate,
  setSupportUseTemplate,
  isSupportUseTemplate,
  setSupportVideoTransitionExtension,
  isSupportVideoTransitionExtension,
  setSupportChangeSpeed,
  isSupportChangeSpeed,
  setSupportClipSplit,
  isSupportClipSplit,
  setSupportVideoColorAdjust,
  isSupportVideoColorAdjust,
  setSupportCaptionStyleSetting,
  isSupportCaptionStyleSetting,
  getTLTimeEveryPX,
  getTLPXEveryTime,
  getNowTimeByPx,
  getPxByNowTime,
  getFSProjectFolder,
  getTemplateFolder,
  getFSVideoFxFolder,
  getFSAnimatedStickerFolder,
  getFSAnimatedStickerInAnimationFolder,
  getFSAnimatedStickerOutAnimationFolder,
  getFSAnimatedStickerAnimationFolder,
  getFSCaptionStyleFolder,
  getFSCaptionContextFolder,
  getFSCaptionRendererFolder,
  getFSCaptionInAnimationFolder,
  getFSCaptionOutAnimationFolder,
  getFSCaptionAnimationFolder,
  getFSThemeFolder,
  getFSTransitionFolder,
  getFSM3u8Folder,
  getFSFontFolder,
  getFSCompoundFolder,
  getModelFolder,
  getARSceneFolder,
  getDatFolder,
  getResourcesFolder,
  getFSRecord,
  getBackgroundImageFolder,
  getInteractProjectFolder,
  getProjectresourceFolder,
  getWorkflow,
  setWorkflow,
  computerFontSizeInResolvingPower,
  FROMMEDIA,
  FROMTRACK,
  FROMMCAPTION,
  VIDEOTRACK,
  VIDEOCLIP,
  AUDIOTRACK,
  AUDIOCLIP,
  CAPTIONTRACK,
  CAPTIONCLIP,
  FS_PROJECT,
  STICKERTRACK,
  STICKERCLIP,
  TIMELINEVIDEOFXTRACK,
  TIMELINEVIDEOFX,
  TRANSITION,
  TIMELINEVIDEOFXTYPEBUILDIN,
  TIMELINEVIDEOFXTYPPACKAGE,
  COMPOUNDCAPTIONTRACK,
  COMPOUNDCAPTIONCLIP,
  PICTUREDEFAULTLENGTH,
  MUSICLYRICSTRACK,
  MUSICLYRICSCLIP,
  AUTOSAVETIME,
  BLANKLINE,
  THUMBNAIL_SPACE,
  AILibretto,
  tempType,
  mediaType,
  RESOLUTION,
  SIZE_LIST
}
