import EventBus from '../../EventBus'
import EventBusKey from '../EventBusKey'
import GL from '../Global'
import NvNetwork from './NvNetwork'
import Enum from '@/utils/enum'
import { Message } from 'element-ui'
import store from '@/store'

const uploadDomId = 'media-upload'
let pic = { types: [], assets: [] }
let video = { types: [], assets: [] }
let audio = { types: [], assets: [] }
let nvsSign = ''
let uid
let unid
// const uid = '6725ad5291c71037a61022cf010852f0'
// const unid = 'f7da90b92c55982dc126a4219e3859f1'
async function getAsperaConfig () {
  // 获取aspera上传
  const r = await myFetch(NvNetwork.data().upload_config)
  if (r) {
    const types = r.file_format

    pic.types = types.image
    video.types = types.video
    audio.types = types.audio

    window._XN_upCfg = {
      asperaPath: r.aspera_path,
      httpPath: r.original_path,
      fileNameId: r.filename_id
    }
  }
}

export async function getConfig () {
  if (sessionStorage.nvseditlogintoken) {
    [uid, unid] = sessionStorage.nvseditlogintoken.split('--')
  }
  await getAsperaConfig()
  const {data: r} = await myFetch(NvNetwork.data().upload_info, {})
  let baseUrl = r.url
  let appId = r.app_id
  let appSecret = r.app_secret
  if (r.url) {
    window.$nvsConfig.streamUpload = r.url + '/'
  }
  window._XN_appCfg = {
    appId: appId,
    appSecret: appSecret,
    filePrefix: r.file_prefix,
    filePreview: r.file_preview
  }
  const keys = uKeys()
  const keysArray = Object.entries(keys).map(item => { return item.join('=') })
  const dataConfig = { // 接口上传信息
    tokenURL: baseUrl + '/v2/tk?' + keysArray.join('&'),
    uploadURL: baseUrl + '/v2/upload',
    postVarsPerFile: {
      appId: appId,
      appSecret: appSecret
    }
  }

  const baseConfig = { // 上传基础信息配置
    customered: true,
    autoUploading: false,
    multipleFiles: true,
    retryCount: 3,
    tokenURL: '',
    uploadURL: '',
    postVarsPerFile: {
      appId: '',
      appSecret: ''
    },
    onMaxSizeExceed: f => {
      Message({
        type: 'warning',
        message: '文件大小超出限制'
      })
      console.log('文件大小超出限制')
    },
    onCancel: f => {
      console.log('cancel', f)
    },
    onExtNameMismatch: () => {
      console.log('无法上传不支持的格式')
    }
  }

  const uploadConfig = { // 上传依赖js配置
    browseFileId: uploadDomId,
    extFilters: [],
    maxSize: 10 * 1024 * 1024 * 1024,
    onQueueComplete: (m) => { // 上传文件全部完成事件
      EventBus.$emit(EventBusKey.uploadAllFinish, m)
    },
    onComplete: f => { // 上传文件完成事件
      f.targetPath = JSON.parse(f.msg).targetPath
      EventBus.$emit(EventBusKey.uploadItmeFinish, f)
      window.nvsUploader.cancelOne(f.id)
    },
    onUploadError: f => { // 上传文件错误事件
      console.log('文件上传出错')
      EventBus.$emit(EventBusKey.uploadError, f)
    },
    onSelect: f => { // 上传文件选择事件
      const isTrimUpload = store.state.layout.isTrimUpload
      if (isTrimUpload) {
        f[0].isTrimUpload = isTrimUpload
        EventBus.$emit(EventBusKey.uploadSelectWithTrim, f)
      } else {
        EventBus.$emit(EventBusKey.uploadSelect, f)
      }
    },
    onUploadProgress: f => { // 上传文件进度事件
      EventBus.$emit(EventBusKey.uploadProgress, f)
    }
  }

  Object.assign(baseConfig, dataConfig)

  const config = Object.assign({}, baseConfig, uploadConfig)

  console.log('上传配置', config)

  window.nvsUploader = new Stream(config)
}

function obj2Str (obj, pre = '?') {
  // 将对象转换为字符串参数
  let strArr = []

  for (let i in obj) {
    strArr.push(`${i}=` + encodeURIComponent(obj[i]))
  }

  return strArr.length > 0 ? pre + strArr.join('&') : ''
}

function uKeys () {
  // 获取用户信息
  const time = Date.parse(new Date()) / 1000
  const nonce = create32()
  const querys = [
    'jsonrpc=2.0',
    `timetamp=${time}`,
    `platform=pceditor`,
    `nonce=${nonce}`
  ]

  if (uid) {
    querys.push(`uid=${uid}`)
  }

  if (unid) {
    querys.push(`unid=${unid}`)
  }

  const sign = generateSign(querys)

  return {
    jsonrpc: '2.0',
    timetamp: time,
    platform: 'pceditor',
    nonce: nonce,
    sign: sign,
    uid: uid,
    unid: unid
  }
}
function getDomain(url) {
  const urlArr = url.split('/')
  if (urlArr[2]) {
    return urlArr[2]
  } else {
    return ''
  }
}
function create32 () {
  // 生成32位随机数
  let nonce = ''
  const str = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ]
  for (var i = 0, len = 32; i < len; i++) {
    nonce += str[Math.floor(Math.random() * str.length)]
  }

  return nonce
}

function generateSign (arr) {
  // 合成sign
  let sign = ''
  arr.sort()
  for (let o in arr) {
    sign += `${arr[o]}&`
  }
  const code = sign.replace(/&$/g, '') + nvsSign

  return sha1(code)
}
function uniKey (length) {
  // 生成唯一文件名
  var g = ''
  var i = length || 32
  var now = +new Date()
  while (i--) {
    g += Math.floor(Math.random() * 16.0).toString(16)
  }

  return g + now
}
function getPlatform () {
  // 获取操作系统版本
  var OS = ''
  var OSArray = {}
  // var UserAgent = navigator.userAgent.toLowerCase()
  OSArray.win = (navigator.platform == 'Win32') || (navigator.platform == 'Windows')
  OSArray.mac = (navigator.platform == 'Mac68K') || (navigator.platform == 'MacPPC')
    || (navigator.platform == 'Macintosh') || (navigator.platform == 'MacIntel')
  // OSArray.iphone = UserAgent.indexOf('iPhone') > -1
  // OSArray.ipod = UserAgent.indexOf('iPod') > -1
  // OSArray.ipad = UserAgent.indexOf('iPad') > -1
  // OSArray.Android = UserAgent.indexOf('Android') > -1
  for (var i in OSArray) {
    if (OSArray[i]) {
      OS = i
    }
  }

  return OS
}
async function myFetch (url, params = {}) {
  const method = params.method || 'post'
  const data = params.data || params
  const domain = getDomain(url)
  nvsSign = Enum.domainKeyMap[domain]
  const options = {}
  options['method'] = method

  const userKeys = uKeys()
  Object.assign(data, userKeys)

  if (method === 'get') {
    url = url + obj2Str(data, '&')
  } else {
    const formData = new FormData()
    for (const k in data) {
      if (data.hasOwnProperty(k)) {
        formData.append(k, data[k])
      }
    }

    options['body'] = formData
  }

  const res = await fetch(url, options)
  const val = await res.json()
  return val
}
function checkFileType(f) {
  // 检查文件类型
  let type = "";
  let ext = f.name.split(".").pop() || f.path.split(".").pop();
  ext = ext.toLowerCase();

  if (pic.types.indexOf(ext) > -1) {
    type = 1;
  } else if (video.types.indexOf(ext) > -1) {
    type = 2;
  } else if (audio.types.indexOf(ext) > -1) {
    type = 3;
  }

  return type;
}

export function resourceAdd ({path, title, type}) {
  return new Promise((resolve, reject) => {
    type = type === undefined ? (checkFileType({name: title, path}) || 0) : type
    const projectId = GL.getProjectId()
    myFetch(NvNetwork.data().webApi_resource_add, {path, title, projectId, type}).then(res => {
      if (res.data.url) {
        resolve(res.data.url.replace(/^http:\/\/|https:\/\//, ''))
      } else {
        reject('add resource fail ' + res.message)
      }
    })
  })
}

export function streamUpload (key, uuid, file, callback, error) {
  key = key.replace(/^\//, '')

  var upConfig = window._XN_upCfg
  var filename = key
  var uniname = uniKey()
  var platform = getPlatform()
  var ext = filename.split('.').pop()
  var fileNameId = upConfig.fileNameId
  var httpPath = upConfig.httpPath
  if (['json', 'xml'].includes(ext.toLowerCase())) {
    // 上传的是工程文件
    httpPath = httpPath.replace('original', 'editor') + '/project'
  }
  // var path = httpPath + `/${uid}/${GL.getProjectId()}/` + fileNameId + platform + uniname + '.' + ext
  var path = httpPath + '/' + (fileNameId || '') + platform + uniname + '.' + ext
  const info = {
    targetPath: path,
    appId: window._XN_appCfg.appId,
    appSecret: window._XN_appCfg.appSecret
  }
  const name = key.split('/').pop()
  const _url = `${NvNetwork.data().upload_steam}?targetPath=${info.targetPath}&appId=${info.appId}&appSecret=${info.appSecret}`
  var _xhr = new XMLHttpRequest
  _xhr.ontimeout = function () {
    console.error('The request for ' + _url + ' timed out.')
  }
  _xhr.onreadystatechange = function () {
    if (_xhr.readyState != 4 || _xhr.status < 200) return false
    let data = JSON.parse(_xhr.responseText)
    if (!data.success) return
    data.targetPath = data.path
    data.name = name
    resourceAdd({path: data.path, title: name}).then(Location => {
      if (callback && typeof callback === 'function') {
        const progressData = {
          keyUUID: uuid,
          progress: 100,
          key: key,
          filename: key.split('/').pop(),
          uploadFinish: true,
          Location
        }
        callback(progressData)
      }
    }).catch(e => {
      if (error && typeof error === 'function') {
        error(e)
      }
    })
  }
  _xhr.onerror = (e) => {
    if (error && typeof error === 'function') {
      error(e)
    }
  }
  _xhr.timeout = 60 * 1000
  _xhr.open('POST', _url, !0)
  _xhr.send(file)
}
