import Global from './Global'
import EventBusKey from './EventBusKey'
import Bus from '../EventBus'
import axios from 'axios'
import Utils from './Utils'
import i18n from '@/i18n'
import { Loading } from 'element-ui'
import store from '@/store'

var name = 'nvBSEditorAssets'
// 6: 跟master分支同步
// 7: 有个字体包没有升级包的版本
// 8: 新加背景图片，字幕动画
// 9: 与smg一致
var version = 11
let db

export function prepareAssetIndexDB (callback, errCallback) {
  let openRequest = window.indexedDB.open(name, version)
  let deleteRequest
  openRequest.onerror = function (e) {
    console.error('open index db error, ', openRequest.error)
    deleteRequest = window.indexedDB.deleteDatabase(name)
    deleteRequest.onsuccess = function (e) {
      openRequest = window.indexedDB.open(name, version)
      openRequest.onsuccess = function (e) {
        db = e.target.result
        console.warn('index db version:' + db.version)
        if (callback) callback()
      }
      openRequest.onupgradeneeded = function (e) {
        console.warn('DB version upgrading...')
        const ret = e.target.result
        _buildAssetIndexedDB(ret)
      }
      openRequest.onerror = function (e) {
        console.error('open index db error, ', openRequest.error)
        if (errCallback) errCallback(new Error('open index db error!'))
      }
    }
    deleteRequest.onerror = function (e) {
      console.error('delete index db error!', deleteRequest.error)
      if (errCallback) errCallback(new Error('delete index db error!'))
    }
    if (errCallback) errCallback(new Error('open index db error!'))
  }
  openRequest.onsuccess = function (e) {
    db = e.target.result
    console.warn('index db version:' + db.version)
    if (callback) callback()
  }
  openRequest.onupgradeneeded = function (e) {
    console.warn('DB version upgrading...')
    const ret = e.target.result
    _buildAssetIndexedDB(ret)
  }
}

function _buildAssetIndexedDB (ret) {
  ensureAssetIndexDBObject(ret, 'm3u8')
  ensureAssetIndexDBObject(ret, 'font')
  ensureAssetIndexDBObject(ret, 'captionstyle')
  ensureAssetIndexDBObject(ret, 'captionrenderer')
  ensureAssetIndexDBObject(ret, 'captioncontext')
  ensureAssetIndexDBObject(ret, 'captioninanimation')
  ensureAssetIndexDBObject(ret, 'captionoutanimation')
  ensureAssetIndexDBObject(ret, 'captionanimation')
  ensureAssetIndexDBObject(ret, 'animatedsticker')
  ensureAssetIndexDBObject(ret, 'animatedstickerinanimation')
  ensureAssetIndexDBObject(ret, 'animatedstickeroutanimation')
  ensureAssetIndexDBObject(ret, 'animatedstickeranimation')
  ensureAssetIndexDBObject(ret, 'transition')
  ensureAssetIndexDBObject(ret, 'compoundcaption')
  ensureAssetIndexDBObject(ret, 'videofx')
  ensureAssetIndexDBObject(ret, 'theme')
  ensureAssetIndexDBObject(ret, 'audiowave')
  ensureAssetIndexDBObject(ret, 'backgroundimage')
  ensureAssetIndexDBObject(ret, 'model')
  ensureAssetIndexDBObject(ret, 'resources')
  ensureAssetIndexDBObject(ret, 'project')
  ensureAssetIndexDBObject(ret, 'template')
  ensureAssetIndexDBObject(ret, 'projectresource')
  ensureAssetIndexDBObject(ret, 'lic')
  ensureAssetIndexDBObject(ret, 'dat')
  ensureAssetIndexDBObject(ret, 'ARScene')
}

function ensureAssetIndexDBObject (ret, name) {
  if (!ret.objectStoreNames.contains(name)){
    ret.createObjectStore(name, {keyPath: 'id'});
  }
}
/**
 *
 * @param {*} key
 * @param {*} checkVersion
 * @param {*} assetType resources属于其他资源, 默认false, 安装水印时使用
 * @param {*} checkLic
 * @returns
 */
export function checkAndInstallAssetFromIndexDB (key, checkVersion = true, assetType, checkLic = true, licenseUrl) {
  checkLic = !['smg'].includes(process.env.CONFIG_NAME)
  const projectId = Global.getProjectId()
  const contextInstance = nvsGetStreamingContextInstance() || nveGetEffectContextInstance()
  return new Promise((resolve, reject) => {
    let path
    let type
    let objectStoreName
    const suffix = key.split('.').pop()
    objectStoreName = suffix
    if (key.toLowerCase().search(/ttc|ttf|otf$/) !== -1) {
      objectStoreName = 'font'
    } else if (key.includes('.arscene')) {
      objectStoreName = 'ARScene'
    } else if (key.includes('.videotransition')) {
      objectStoreName = 'transition'
    } else if (['.template', '.project'].includes(suffix)) {
      objectStoreName = 'template'
    }
    path = '/' + objectStoreName
    type = Enum.assetPackageType[objectStoreName]
    if (assetType) {
      if (assetType === 'projectresource') {
        path = Global.getProjectresourceFolder()
        // path = contextInstance.getAssetPackageManager().getTemplatePackageDirPath(projectId)
        let subFolderList = key.split('/')
        if (subFolderList.length > 1) {
          let wholeFolderPath = path
          for (let i = 0; i < subFolderList.length - 1; i++) {
            wholeFolderPath += '/' + subFolderList[i]
            if (!FS.analyzePath(wholeFolderPath).exists) {
              FS.mkdir(wholeFolderPath)
            }
          }
        }
        objectStoreName = assetType
      } else {
        path = '/' + assetType
        type = assetType
        objectStoreName = assetType
      }
    }

    let filePath = path + '/' + key
    let keyUUID = key.split('.')[0]
    if (!keyUUID) {
      keyUUID = Utils.getUUIDFromStr(key)
    }
    // 对于特效素材，安装前判断是否已被安装过了
    if (type >= 0) {
      const status = contextInstance
        .getAssetPackageManager()
        .getAssetPackageStatus(keyUUID, type)
      if (status !== NvsAssetPackageStatusEnum.NotInstalled) {
        resolve(key)
        return
      }
    }
    const versionFromUrl = Utils.getVersionFromStr(key)
    let transaction = db.transaction(objectStoreName, 'readwrite')
    let store = transaction.objectStore(objectStoreName)
    let request = store.get(keyUUID)
    request.onsuccess = function (e) {
      let ret = e.target.result
      if (typeof ret === 'undefined' || typeof ret.data === 'undefined') {
        // console.log(key, ' is not exist, need download');
        reject(key)
        return
      }
      if (!ret.name.includes(keyUUID)) {
        console.warn(key, ' is not exist, version is not right, need download');
        reject(key)
        return
      }
      const versionFromDB = Utils.getVersionFromStr(ret.name)
      if (checkVersion && versionFromDB < versionFromUrl) {
        console.warn(`${key} version has updated from ${versionFromDB} to ${versionFromUrl}, need download`);
        reject(key)
      } else {
        FS.writeFile(filePath, new Uint8Array(ret.data))
        if ( ['model', 'dat'].includes(type)) {
          resolve()
        } else if (type >= 0) {
          if (checkLic) {
            let requestLic = db.transaction('lic', 'readwrite').objectStore('lic').get(keyUUID)
            requestLic.onsuccess = function (e) {
              let licFilePath = ''
              let requestLicResult = e.target.result
              if (typeof requestLicResult !== 'undefined' && typeof requestLicResult.data !== 'undefined') {
                licFilePath = path + '/' + keyUUID + '.lic'
                FS.writeFile(licFilePath, new Uint8Array(requestLicResult.data))
              }
              Bus.$on(EventBusKey.onFinishAssetPackageInstallation + filePath, async function slot (assetPackageId, assetPackageFilePath, assetPackageType, error) {
                FS.unlink(assetPackageFilePath, 0)
                if (licFilePath) FS.unlink(licFilePath, 0)
                Bus.$off(EventBusKey.onFinishAssetPackageInstallation + filePath, slot)
                resolve(key)
                if (error !== 0) {
                  console.error(`资源安装失败${key} 错误码: ${error}`)
                  if (licenseUrl) {
                    console.info('尝试重新下载...')
                    // Download lic file
                    const licData = await axios.get(licenseUrl, { responseType: 'arraybuffer' })
                    await saveAssetToIndexDB(key.split('.')[0] + '.lic', new Uint8Array(licData.data))
                  }
                }
                // if (error === 0) resolve(key)
                // else reject(new Error(`资源安装失败${key} 错误码: ${error}`))
              })
              // 加载已有工程时会预先加载资源，此时遍历资源列表时，返回值为2，不会接收到，无法resolve
              contextInstance.getAssetPackageManager().installAssetPackage(filePath, licFilePath, type)
            }
            requestLic.onerror = function (e) {
              console.error(e)
              reject(key)
            }
          } else {
            Bus.$on(EventBusKey.onFinishAssetPackageInstallation + filePath, function slot(assetPackageId, assetPackageFilePath, assetPackageType, error) {
              // console.log(filePath, '安装完成回调')
              FS.unlink(assetPackageFilePath, 0)
              Bus.$off(EventBusKey.onFinishAssetPackageInstallation + filePath, slot)
              if (error === 0) {
                resolve(key)
              } else {
                resolve()
                console.error(`资源安装失败${key} 错误码: ${error}`)
              }
            })
            contextInstance.getAssetPackageManager().installAssetPackage(filePath, '', type)
          }
        } else if (type === -2) {
          var fontFamily = contextInstance.registerFontByFilePath(filePath)
          resolve(fontFamily)
        } else {
          resolve(filePath)
        }
      }
    }
    request.onerror = function (event) {
      console.error(event)
      reject(key)
    }
  })
}
export function getAssetFromIndexDB (id, type) {
  const storeNames = Global.TEMPLATE_PACKAGES
  if (!storeNames.includes(type)) return null
  return new Promise((resolve, reject) => {
    let transaction = db.transaction(type, 'readwrite')
    let store = transaction.objectStore(type)
    let request = store.get(id)
    request.onsuccess = (e) => {
      const ret = e.target.result
      if (ret) {
        resolve({ data: new Uint8Array(ret.data), name: ret.name })
      } else {
        resolve({})
      }
    }
    request.onerror = () => {
      resolve({})
    }
  })
}
/**
 *
 * @param {*} key
 * @param {*} value
 * @param {*} assetType resources属于其他资源, 默认false, 安装水印时使用
 * @returns
 */
export function saveAssetToIndexDB (key, value, assetType) {
  return new Promise((resolve, reject) => {
    let objectStoreName
    const suffix = key.split('.').pop()
    objectStoreName = suffix
    if (key.toLowerCase().search(/ttc|ttf|otf$/) !== -1) {
      objectStoreName = 'font'
    } else if (key.includes('.videotransition')) {
      objectStoreName = 'transition'
    } else if (['.template', '.project'].includes(suffix)) {
      objectStoreName = 'template'
    } else if (key.includes('.arscene')) {
      objectStoreName = 'ARScene'
    } else if (assetType) {
      objectStoreName = assetType
    }
    let keyUUID = key.split('.')[0]
    if (!keyUUID) {
      keyUUID = Utils.getUUIDFromStr(key)
    }
    if (db !== undefined && db.objectStoreNames.contains(objectStoreName)) {
      var transaction = db.transaction(objectStoreName, 'readwrite')
      var store = transaction.objectStore(objectStoreName)
      store.put({id: keyUUID, name: key, data: value})
      resolve()
    } else {
      console.error(objectStoreName + ' is not prepared while adding data ----', key)
      reject(new Error(objectStoreName + ' is not prepared while adding data ----' + key))
    }
  })
}

export function saveAndInstallPackage (asset, assetType) {
  return new Promise(async (resolve, reject) => {
    if (!asset) {
      reject(new Error('asset is invalid'))
      return
    }
    let url = asset.packageUrl || asset.packageRelativePath
    let licUrl = asset.licenseUrl
    let packageName = url.substring(url.lastIndexOf('/') + 1)
    try {
      const filePath = await checkAndInstallAssetFromIndexDB(packageName, true, assetType)
      // console.log('install finish, ', packageName)
      resolve(filePath)
    } catch (e) {
      let loading
      if (asset.materialType === Enum.mType.font && !store.state.material.defaultFontInstalling) {
        loading = Loading.service({
          text: i18n.t('loading.font'),
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)'
        })
      }
      try {
        const response = await axios.get(url, { responseType: 'arraybuffer' })
        await saveAssetToIndexDB(packageName, new Uint8Array(response.data), assetType)
        if (licUrl) {
          try {
            let keyUUID = packageName.split('.')[0]
            if (!keyUUID) {
              keyUUID = Utils.getUUIDFromStr(packageName)
            }
            const response = await axios.get(licUrl, { responseType: 'arraybuffer' })
            await saveAssetToIndexDB(keyUUID + '.lic', new Uint8Array(response.data))
            const filePath = await checkAndInstallAssetFromIndexDB(packageName)
            // console.log('download and install with license finish, ', packageName)
            resolve(filePath)
          } catch (e) {
            console.error(e)
            reject(new Error('download and install with license failed, ', packageName))
          }
        } else {
          try {
            const filePath = await checkAndInstallAssetFromIndexDB(packageName, true, assetType)
            // console.log('download and install finish, ', packageName)
            resolve(filePath)
          } catch (e) {
            console.error(e)
            reject(new Error('download and install failed, ', packageName))
          }
        }
        loading?.close()
      } catch (e) {
        loading?.close()
        console.error(e)
        reject(new Error('save asset failed, ', packageName))
      }
    }
  })
}

export function setAudioWaveData (fileName, value) {
  return new Promise((resolve, reject) => {
    if (!db) {
      let request = window.indexedDB.open(name, version)
      request.onerror = function (e) {
        reject(fileName)
        console.error('open index db error!')
      };
      request.onsuccess = function (e) {
        db = e.target.result
        console.log("index db version:" + db.version)
        doSetAssetData(db, fileName, value, 'audiowave', resolve)
      };
    } else {
      doSetAssetData(db, fileName, value, 'audiowave',resolve)
    }
  })
}

export function getAudioWaveData (fileName) {
  return new Promise((resolve, reject) => {
    if (db == undefined) {
      let request = window.indexedDB.open(name, version);
      request.onerror = function(e) {
        reject(fileName)
        console.error('open index db error!');
      };
      request.onsuccess = function(e) {
        db = e.target.result;
        console.log("index db version:" + db.version);
        doGetAssetData (db, fileName, 'audiowave', resolve, reject)
      }
    } else {
      doGetAssetData (db, fileName, 'audiowave',resolve, reject)
    }
  })
}

function doSetAssetData (db, fileName, value, objectStoreName, resolve) {
  if (db.objectStoreNames.contains(objectStoreName)) {
    const transaction = db.transaction(objectStoreName, 'readwrite')
    const store = transaction.objectStore(objectStoreName)
    // const uuid = Utils.getUUIDFromStr(fileName)
    const uuid = fileName.split('.')[0]
    store.put({id: uuid, name: fileName, data: value})
    resolve()
  }
}

function doGetAssetData (db, fileName, objectStoreName, resolve, reject) {
  if (db.objectStoreNames.contains(objectStoreName)) {
    let transaction = db.transaction(objectStoreName,'readwrite');
    let store = transaction.objectStore(objectStoreName);
    let uuid = fileName.split('.')[0]
    if (!uuid) {
      uuid = Utils.getUUIDFromStr(fileName)
    }
    let request = store.get(uuid);
    request.onsuccess = function(e) {
      let ret = e.target.result;
      if (typeof ret === 'undefined' || typeof ret.data === 'undefined') {
        reject(fileName)
      } else {
        resolve(ret.data)
      }
    }
  }
}

export function deleteIndexDBByIndexAndName(index, objectStoreName) {
  const transaction = db.transaction(objectStoreName, 'readwrite')
  const store = transaction.objectStore(objectStoreName)
  store.delete(index)
}
