import { get, post, delet } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {

  resource_create: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_resource_create,
      data,
      headers: {
        params: { ...props }
      }
    }),

  resource_update: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_resource_update,
      data,
      headers: {
        params: { ...props }
      }
    }),

  resource_yicai_create: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_resource_yicai_create,
      data,
      headers: {
        params: { ...props }
      }
    }),

  resource_delete: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_resource_delete,
      data,
      headers: {
        params: { ...props }
      }
    }),
  resource_batch_delete: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_resource_batch_delete,
      data,
      headers: {
        params: { ...props }
      }
    }),

  resource_append: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_resource_append,
      data,
      headers: {
        params: { ...props }
      }
    }),

  resource_list: ({ ...props }) => {
    if (!process.env.PERM_LIST.b_startPageNumberZero && process.env.NODE_ENV !== 'development') {
      props.page++
    }
    return get({
      url: NvNetwork.data().webApi_resource_list,
      headers: {
        version: 'v2',
        params: { ...props }
      }
    })
  },

  resource_info: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_resource_info,
      headers: {
        params: { ...props }
      }
    }),

  resource_tag_info: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_resource_tag_info,
      headers: {
        params: { ...props }
      }
    }),

  sts_info2: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_sts_info2,
      data,
      headers: {
        params: { ...props }
      }
    }),

  upload_file_local: (data, { ...props }, onUploadProgress) =>
    post({
      url: NvNetwork.data().webApi_sts_upload_local,
      data,
      onUploadProgress
    }),

  resource_add: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_resource_add,
      data,
      headers: {
        params: { ...props }
      }
    }),

  resource_updtitle: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_resource_updtitle,
      data,
      headers: {
        params: { ...props }
      }
    }),

  resource_reverse: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_resource_reverse,
      headers: {
        params: { ...props }
      }
    }),
}
