import Global from "@/api/Global"
import store from '@/store'

export default {
  namespaced: true,
  state: {
    rulerRule: 8,
    isThumbnailAll: true,
    refreshTimelineUI: 0,
    _combinationMap: new Map(),
    // 空闲的order
    _combinationSpareOrder: [],
    _combinationMap_template: new Map(),
    _combinationSpareOrder_template: []
  },
  getters: {
    pixelPerMicrosecond (state) {
      const rulingSpace = Global.TimelineRules[state.rulerRule].rulingSpace
      const tipSpace = Global.TimelineRules[state.rulerRule].tipSpace
      return rulingSpace / tipSpace
    },
    combinationMap (state) {
      if (store.state.currentPanelType === 'TemplatePanel') {
        return state._combinationMap_template
      } else {
        return state._combinationMap
      }
    },
    combinationSpareOrder (state) {
      if (store.state.currentPanelType === 'TemplatePanel') {
        return state._combinationSpareOrder_template
      } else {
        return state._combinationSpareOrder
      }
    }
  },
  mutations: {
    toggleThumbnailAll (state) {
      state.isThumbnailAll = !state.isThumbnailAll
    },
    addCombinationSpareOrder (state, payload) {
      const orders = Array.isArray(payload) ? [...payload] : [payload]
      if (store.state.currentPanelType === 'TemplatePanel') {
        state._combinationSpareOrder_template.push(...orders)
      } else {
        state._combinationSpareOrder.push(...orders)
      }
    },
    setCombinationMap (state, payload) {
      const { order, data } = payload
      if (store.state.currentPanelType === 'TemplatePanel') {
        state._combinationMap_template.set(order, data)
      } else {
        state._combinationMap.set(order, data)
      }
    },
    deleteCombinationMap (state, payload) {
      if (store.state.currentPanelType === 'TemplatePanel') {
        state._combinationMap_template.delete(payload)
      } else {
        state._combinationMap.delete(payload)
      }
    },
    clearCombinationMap (state) {
      if (store.state.currentPanelType === 'TemplatePanel') {
        state._combinationMap_template.clear()
      } else {
        state._combinationMap.clear()
      }
    },
    updateRulerRule (state, payload) {
      state.rulerRule = payload
    },
    refreshTimelineUI (state) {
      state.refreshTimelineUI += 1
    }
  }
}
