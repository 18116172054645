import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.CONTEXT ? `/${process.env.CONTEXT}/` : '',
  routes: [
    {
      path: '/App',
      name: 'App',
      component: () => import('../App')
    }
  ]
})

// 临时数组，将会被反复赋值
let tempRoutes = []

// 开发环境下提供单独测试页面
if (process.env.NODE_ENV === 'development') {
  tempRoutes = [
    {
      path: '/tt',
      name: 'tt',
      component: () => import('../test/TestTimeline.vue')
    }
  ]
  router.addRoutes(tempRoutes)
}

// 关于根目录的配置
if (process.env.CONFIG_NAME !== 'multistream') {
  tempRoutes = [
    {
      path: '/',
      beforeEnter: (to, from, next) => {
        if (process.env.PERM_LIST.p_home) {
          next('/Home')
        } else {
          next()
        }
      },
    },
  ]
  router.addRoutes(tempRoutes)
}

// 关于项目列表等主页的配置
if (process.env.PERM_LIST.p_home || process.env.NODE_ENV === 'development') {
  tempRoutes = [
    {
      path: '/Home',
      name: 'Home',
      component: () => import('@/components/home/Home'),
      redirect: '/Home/ProjectList',
      children: [
        {
          path: 'ProjectList',
          component: () => import(/* webpackChunkName: "home" */ '@/components/home/ProjectList'),
          name: 'ProjectList'
        },
        {
          path: 'MyPieces',
          component: () => import(/* webpackChunkName: "home" */ '@/components/home/MyPieces'),
          name: 'MyPieces'
        },
        {
          path: 'TemplateList',
          component: () => import(/* webpackChunkName: "home" */ '@/components/nvsTemplate/TemplateList'),
          name: 'TemplateList'
        }
      ]
    }
  ]
  router.addRoutes(tempRoutes)
}

// 关于登录页的配置
if (process.env.PERM_LIST.p_login || process.env.NODE_ENV === 'development') {
  tempRoutes = [
    {
      path: '/Register',
      name: 'Register',
      component: () => import('@/components/login/index'),
      meta: { title: 'router.login.register' },
      hidden: true
    },
    {
      path: '/GetBackPassword',
      name: 'GetBackPassword',
      component: () => import('@/components/login/index'),
      meta: { title: 'router.login.getBackPassword' },
      hidden: true
    },
    {
      path: '/Login',
      name: 'Login',
      component: () => import('@/components/login/index')
    }
  ]
  router.addRoutes(tempRoutes)
}

// 关于直播剪的配置
if (process.env.PERM_LIST.p_live) {
  if (process.env.CONFIG_NAME === 'yicai') {
    tempRoutes = [
      {
        path: '/LiveApp/:liveType/:projectId',
        name: 'LiveApp',
        component: () => import('../components/liveAppYicai/LiveApp')
      }
    ]
  } else {
    tempRoutes = [
      {
        path: '/LiveApp/:projectId',
        name: 'LiveApp',
        component: () => import('../components/liveApp/LiveApp')
      }
    ]
  }
  router.addRoutes(tempRoutes)
}

// 关于粗剪的配置
if (process.env.PERM_LIST.p_coarse) {
  tempRoutes = [
    {
      path: '/CoarseApp',
      name: 'CoarseApp',
      component: () => import('@/components/coarseApp/index')
    }
  ]
  router.addRoutes(tempRoutes)
}

// 关于模板的配置
if (process.env.PERM_LIST.p_template) {
  tempRoutes = [
    {
      path: '/Template',
      name: 'Template',
      component: () => import('../components/nvsTemplate/TemplateApp')
    }
  ]
  router.addRoutes(tempRoutes)
}

// 关于文字剪的配置
if (process.env.PERM_LIST.p_textvideo) {
  tempRoutes = [
    {
      path: '/TextVideo',
      name: 'TextVideo',
      component: () => import('@/components/textVideo/index')
    }
  ]
  router.addRoutes(tempRoutes)
}

export default router
