<template>
  <browser
    v-if="!isEquipmentSupport || !isBrowserSupport || !isBrowserVersionSupport || !isBrowserParamsSupport || !isBrowserModeSupport"
    :isEquipmentSupport="isEquipmentSupport"
    :isBrowserSupport="isBrowserSupport"
    :isBrowserVersionSupport="isBrowserVersionSupport"
    :isBrowserParamsSupport="isBrowserParamsSupport"
    :updateParamsIndex="updateParamsIndex"
    :isBrowserModeSupport="isBrowserModeSupport"
  ></browser>
  <router-view v-else></router-view>
</template>

<script>
import Browser from './components/home/Browser'
import browserMixin from './browserMixin'

export default {
  name: 'Root',
  mixins: [browserMixin],
  components: {
    Browser
  },
  mounted () {
    document.getElementById('MSFavicon').href = process.env.PERM_LIST.text_faviconBase64
    document.getElementById('MSTitle').innerHTML = process.env.PERM_LIST.text_titleTitle || document.getElementById('MSTitle').innerHTML
    if (process.env.CONFIG_NAME === 'smg') {
      const scriptTag1 = document.createElement('script')
      scriptTag1.src = '/static/nvsScript/stream-v1.js'
      document.head.appendChild(scriptTag1)
      const scriptTag2 = document.createElement('script')
      scriptTag2.src = '/static/nvsScript/sha1.js'
      document.head.appendChild(scriptTag2)
      const scriptTag3 = document.createElement('script')
      scriptTag3.src = '/static/nvsScript/encrypt.js'
      document.head.appendChild(scriptTag3)
    }
    if (process.env.NODE_ENV === 'ksyun') {
      const scriptTag1 = document.createElement('script')
      scriptTag1.src = '/static/ksyun/plupload.full.min.js'
      document.head.appendChild(scriptTag1)
      const scriptTag2 = document.createElement('script')
      scriptTag2.src = '/static/ksyun/ks3jssdk.min.js'
      document.head.appendChild(scriptTag2)
      const scriptTag3 = document.createElement('script')
      scriptTag3.src = '/static/ksyun/async.min.js'
      document.head.appendChild(scriptTag3)
    }
  }
}
</script>
