import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import monitor from './modules/monitor'
import split from './modules/split'
import user from './modules/user'
import layout from './modules/layout'
import keyFrame from './modules/keyFrame'
import textVideo from './modules/textVideo'
import live from './modules/live'
import material from './modules/material'
import project from './modules/project'
import resource from './modules/resource'
import rightMenu from './modules/rightMenu'
import timeline from './modules/timeline'
import template from './modules/template'

Vue.use(Vuex)
const store = new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  modules: {
    monitor,
    split,
    user,
    layout,
    keyFrame,
    textVideo,
    material,
    live,
    project,
    resource,
    rightMenu,
    timeline,
    template
  },
  // strict: process.env.NODE_ENV !== 'production'
})

export default store
