import { get, post, delet } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {

  userInfo: ({ ...props }) =>
    post({
      url: NvNetwork.data().webApi_userInfo,
      headers: {
        params: { ...props }
      }
    }),

  login: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_login,
      data,
      headers: {
        params: { ...props }
      }
    }),

  judgeUserExist: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_judgeUserExist,
      data,
      headers: {
        params: { ...props }
      }
    }),

  verify_code_image_create: ({ ...props }) =>
    post({
      url: NvNetwork.data().webApi_verify_code_image_create,
      headers: {
        params: { ...props }
      }
    }),

  checkPhone: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_checkPhone,
      data,
      headers: {
        params: { ...props }
      }
    }),

  checkEmail: (token, data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_checkEmail,
      data,
      headers: {
        token,
        params: { ...props }
      }
    }),
  
  checkPassword: (token, data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_checkPassword,
      data,
      headers: {
        token,
        params: { ...props }
      }
    }),
  
  forwardToEmail: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_forwardToEmail,
      data,
      headers: {
        params: { ...props }
      }
    }),

  webApi_verify_code_create: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_verify_code_create,
      data,
      headers: {
        params: { ...props }
      }
    }),

  register: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_register,
      data,
      headers: {
        params: { ...props }
      }
    }),
}
