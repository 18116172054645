export default {
  namespaced: true,
  state: {
    projectData: null
  },
  mutations: {
    updateProjectData (state, payload) {
      if (!payload.resolvingPower) {
        payload.resolvingPower = parseInt(payload.resolvingPower) || 720
      }
      state.projectData = payload
    }
  }
}
