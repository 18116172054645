import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
import zh from './config/zh'
import en from './config/en'

const i18n = new VueI18n({
  locale: getLocale(),
  messages: {
    zh,
    en,
  }
})

Vue.prototype.$getLocale = getLocale
export function getLocale () {
  // 以下环境必使用英文
  if (['multistream'].includes(process.env.CONFIG_NAME)) {
    localStorage.language = 'en'
    return 'en'
  }
  // 没设置语言，默认使用中文
  if (!localStorage.language) {
    localStorage.language = 'zh'
    return 'zh'
  } else {
    return localStorage.language
  }
}

export default i18n
