import { get, post, delet } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {

  video_list: ({ ...props }) => {
    if (!process.env.PERM_LIST.b_startPageNumberZero && process.env.NODE_ENV !== 'development') {
      props.page++
    }
    return get({
      url: NvNetwork.data().webApi_video_list,
      headers: {
        params: { ...props }
      }
    })
  },

  video_delete: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_video_delete,
      data,
      headers: {
        params: { ...props }
      }
    }),

  video_create: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_video_create,
      data,
      headers: {
        params: { ...props }
      }
    }),
}
