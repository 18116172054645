const en = {
  enum: {
    materialType: {
      'all': 'All',
      'video': 'Video',
      'audio': 'Audio',
      'picture': 'Picture',
      'folder': 'Folder'
    },
    compileJobStatus: {
      doing: 'Doing',
      done: 'Finished',
      fail: 'Failed'
    },
    mType: {
      theme: 'Theme',
      videofx: 'Filter',
      captionstyle: 'Caption',
      animatedsticker: 'Sticker',
      videotransition: 'Transition',
      font: 'Font',
      music: 'Music',
      particle: 'Particle',
      arscene: 'ARScene',
      compoundCaption: 'Compound Caption',
      photoAlbum: 'Photo Album',
      mimo: 'MIMO',
      vlog: 'Shooting Template',
      template: 'Template',
      animationVideofx: 'Filter Animation',
      backgroundImage: 'Background Image',
      animationCaption: 'Caption Animation'
    },
    backgroundFillType: {
      color: 'Color',
      image: 'Image',
      blur: 'Blur',
    },
    splitCategory: {
      clip: 'Clip',
      people: 'People',
      voice: 'Voice',
      word: 'Word'
    },
    projectTag: {
      live: 'Live Editor',
      template: 'Template',
      split: 'Split',
      textVideo: 'Text Video',
      meiying: 'MYVideo',
      interact: 'Interact',
      cover: 'Cover Make'
    },
    materialAuthType: {
      all: 'All',
      public: 'Public',
      authed: 'Authed',
      private: 'Private'
    },
    materialStatus: {
      progress: 'Progress',
      failed: 'Not Transcoding',
      success: 'Usable'
    },
    accountType: {
      'mainAccount': 'Main Account',
      'subAccount': 'Sub Account'
    },
    mediaEditTab: {
      basic: 'Basic Settings',
      speed: 'Speed',
      filter: 'Filter',
      effect: 'Effect',
      colorCorrection: 'Color Correction',
      audio: 'Audio',
      keyer: 'Master Keyer',
      animation: 'Animation',
      mask: 'Mask',
      background: 'Background'
    },
    captionEditTab: {
      basic: 'Edit Caption',
      renderer: 'Effects',
      context: 'Bubble',
      animation: 'Animation',
      align: "Align",
    },
    stickerEditTab: {
      basic: 'Edit Sticker',
      animation: 'Animation'
    },
    curveSpeed: {
      giveupHint: 'Give Up Curve Speed, Use Regular Speed?',
      line: 'Linear',
      curve: 'Curve',
      custom: 'Custom',
      hero: 'Hero of the moment',
      bullet: 'The bullet time',
      montage: 'Montage',
      jumper: 'Jumper',
      flashInto: 'Flash into',
      flash: 'Flash',
    },
    textTool: {
      edit: 'Edit',
      merge: 'Merge',
      split: 'Split',
      sticker: 'Sticker',
      caption: 'Caption',
      stand: 'Stand',
      template: 'Template',
      delete: 'Del',
      subsection: 'Subsection'
    },
    liveTabName: {
      clipList: 'Clip List',
      audioToText: 'Audio To Text'
    },
    captionFormat: {
      txt: 'txt File',
      srt: 'srt File',
      csv: 'csv File'
    },
    coarseJobStatus: {
      waiting: 'waiting',
      compileDoing: 'compileDoing',
      compileDone: 'compileDone',
      compileFail: 'compileFail',
      transcodeDoing: 'transcodeDoing',
      transcodeDone: 'transcodeDone',
      transcodeFail: 'transcodeFail'
    },
    letterSpacingType: {
      percentage: 'Percentage',
      absolute: 'Absolute'
    },
    liveTabName: {
      clipList: 'Clip List',
      audioToText: 'Audio To Text'
    },
    captionFormat: {
      txt: 'txt File',
      srt: 'srt File',
      csv: 'csv File'
    },
    replaceType: {
      videoImage: 'Any',
      image: 'Only Image',
      video: 'Only Video'
    }
  },
  'views': {
    'login': {
      'logo1': 'Intelligent Video',
      'logo2': 'Production Platform With Multi-terminal,',
      'logo3': 'A New Experience',
      'username': 'Username',
      'password': 'Password',
      'login': 'Login',
      'register': 'Register',
      'loginTitle': 'Login',
      'registerTitle': 'Register',
      'forgetPassword': 'Forget Password',
      'loginButton': 'Login',
      'registerButton': 'Register',
      'hasNoAccount': 'No Account',
      'hasAlreadyAccount': 'Account already exists, login',
      'email': 'Email',
      'phone': 'Phone Number',
      'verifyCode': 'Verification code',
      'imageCode': 'Image Code',
      'getBackPassword': 'Getback Password',
      'nextStep': 'Next',
      'finishForget': 'Finish Setting',
      'mailSentSuccess': 'Email sent successfully',
      'ph_account': 'Email/Phone Number',
      'ph_password': '6-18 chars, only letters or numbers',
      'ph_email': 'Email',
      'ph_phone': 'Phone Number',
      'ph_password_confirm': 'Confirm password',
      'ph_verifyCode': 'Enter verification code',
      'ph_imageCode': 'Image valid code'
    }
  },
  'router': {
    'login': {
      'register': 'Register',
      'getBackPassword': 'Find Password'
    }
  },
  'valid': {
    'isRequired': 'This is required',
    'notNull': ' can not be empty',
    'categoryNotNull': 'Category can not be empty',
    'minLength': 'Min length ',
    'maxLength': 'Max Length ',
    'lengthIs': ' Length ',
    'inputPasswordAgain': 'Please enter the password again',
    'passwordNotSame': 'Passwords are not the same',
    'phoneSame': 'The old and new phone numbers cannot be the same',
    'emailSame': 'The old and new email accounts cannot be the same',
    'verifyCodeErr': 'Verification code error',
    'notRightEmailPattern': 'Incorrect E-mail format',
    'notRightPhonePattern': 'Incorrect phone format',
    'notRightPasswordPattern': 'Password must contain numbers and letters',
    'emailExist': 'Email already exists',
    'phoneExist': 'Phone number already exists',
    'phoneNotRegister': 'The phone number is not registered',
    'emailNotRegister': 'The Email is not registered',
    'uploadBusinessLicense': 'Please upload business license',
    'iosAppIdNotNull': 'Bundle ID cannot be empty',
    'androidAppIdNotNull': 'Package Name cannot be empty',
    'appIdNotNull': 'Mini Program ID cannot be empty',
    'atLeastOneAppId': 'Please fill in at least one APP ID',
    'atLeastOneVideoClip': 'Theme need at least one video clip',
    'tagsIncluded': 'Label already contains',
    'tagsOver': 'Maximum number of tags reached',
    'tagsEmpty': 'Tags cannot be empty',
    'materialSizeNotRight': 'Size is not satisfied',
    'materialRatioNotRight': 'Ratio is not satisfied',
    'packageNotUploaded': 'Please upload package first'
  },
  home: {
    projects: 'Creative Studio',
    pieces: 'My Videos',
    template: 'Template Center',
    create: 'Video Creation',
    btnCreateNew: 'CREATE NEW',
    cardTitle: 'Video Editor',
    point: 'The system is only used for demonstration. The assets you uploaded will be clean up after 48 hours. Please contact us if needed!',
    cardDes: 'Free to make original videos',
    myProject: 'My Projects',
    createTime: 'Creation Time',
    alterTime: 'Alter Time',
    normalTitle: 'Create New Project',
    searchHint: 'Enter',
    deletes: 'Delete',
    download: 'Download',
    publish: 'Publish',
    userManage: 'Manage',
    lastModified: 'Last Modified: ',
    creativeTime: 'Creative Time: ',
    selectAll: 'Select All(Selected {0} Material)',
    selectSize: 'Select Project Size',
    inputProName: 'Input project name',
    submit: 'Confirm',
    splitHint1: 'Intelligent analysing...',
    splitHint2: 'After the analysis, the next step can be carried out',
    splitHint3: 'Intelligent analysised',
    splitHint4: 'Click to enter',
    splitHint5: 'Intelligent analysis failed',
    splitHint6: 'Convert audio to text failed',
    interactHint: 'Converting...',
    ratio: 'Ratio',
    projectName: 'Project Name',
    title_deleteProject: 'Are you sure you want to delete {msg}?',
    ph_ratio: 'Select Ratio',
    ph_name: 'eg: My Project',
    resolvingPower: 'Resolving Power',
    effectsPlatform: 'Effects Creation Platform'
  },
  userLogin: {
    login: 'Login',
    logout: 'Logout',
    register: 'Register',
    registerSucceed: 'Register succeed, login please',
    registerFailed: 'Register failed',
    registerCodeError: 'Code is not right',
    alreadyRegistered: 'Already registered',
    phone: 'Enter Cellphone Number',
    pwd: 'Enter Password',
    affirm: 'Please confirm the password again',
    code: 'Input verification code',
    getCode: 'Get Code',
    notAccount: 'Haven\'t account?',
    registerNow: 'Register now',
    forgotPassword: 'Forgot password?',
    retrievePassword: 'Retrieve Password',
    resetPassword: 'Reset Password',
    updateUserException: 'Failed to update user info: exception occurred',
    updateUserPasswordEmpty: 'Failed to update user info: password is empty',
    updateUserSuccess: 'Success to update user info',
    registerVerifyCodeWrong: 'Verify code is wrong',
    userNotFound: 'Failed to find user',
    haveAccount: 'Existing account?',
    loginNow: 'Login Now',
    numberNotEmpty: 'The number cannot be empty',
    codeFailed: 'Failed to obtain captcha!Please try again',
    imgCodeFailed: 'verify code in image is wrong',
    pwdNotAffirm: 'The password is not the same. Please re-enter it',
    registerSuccess: 'You have successfully registered!Please log in',
    loginPasswordWrong: 'Failed to login: password is wrong',
    inputImageCode: 'Please input the image verification code first'
  },
  audioContextTip: 'Need open web audio context',
  audioContextOk: 'OK',
  titleBar: {
    bcutHint: 'Please Switch To 2.0 Version For BCut Template Production',
    version: 'V' + window.$nvsConfig.SDKVersion.replace(/_/g, '.'),
    title: 'Intelligent Video Production Platform',
    cloudTitle: 'Meishe\'s Cloud-Based Video Editor',
    liveTitle: 'Meishe\'s Live Video Editor',
    historyVersion: 'History Version',
    currentVersion: 'Current Version',
    isCurrent: 'Current',
    updateNote: 'Updat Note',
    historyVersionDialog: {
      title: 'History Version',
      manualSave: 'Manual Save',
      autoSave: 'Auto Save',
      emptyText: 'There is no history of preservation',
      openLoading: 'open...',
      open: 'open'
    },
    task: 'Task',
    shortcuts: 'Hot Key',
    export: 'Export Video',
    generate: 'Generate Video',
    refreshing: 'Refresh',
    back: 'Return',
    backEditProject: 'Back to clip project',
    upload: 'Upload',
    generalUpload: 'Upload',
    trimUpload: 'Upload with trim',
    record: 'Record',
    save: 'Save',
    saveAs: 'Save as...',
    exportProjectXml: 'Export Project Xml',
    saveInteractProjectFailed: 'Failed of save Interact Project',
    exportPRXML: 'Export XML',
    multipleChoice: 'MultipleChoice',
    multipleChoiceClose: 'Cancel ',
    multipleChoiceAddToTrack: 'Add To Track',
    trimFileSizeOutRange: 'File selected is not supported larger than 2G',
    triming: 'Triming file ...',
    selectSingleFile: 'Please select only one file to trim',
    unsupportedFormat: 'The file format is not supported',
    videoNotSupported: 'Video file format is not support play'
  },
  featureBar: {
    media: 'Media',
    caption: 'Text Titles',
    sticker: 'Sticker',
    music: 'Music',
    theme: 'Theme',
    transition: 'Transition',
    filter: 'Filter',
    particle: 'Particle',
    regulate: "Color Correction",
    effect: 'Effect',
    mask: 'Mask',
    template: 'Template',
    all: 'All',
    video: 'Video',
    audio: 'Audio',
    image: 'Image',
    AILibretto: 'AI Libretto Captions',
    tag: 'Media Tags'
  },
  AILibretto: {
    languageRecognition: 'Language Recognition',
    pleaseSelect: 'Please select the video or audio clip you want to identify',
    identifying: 'Identifying',
    noAudio: 'The Clip No Audio',
    apply: 'Apply',
    applyToTimeline: 'Apply To Timeline',
    cancel: 'Cancel',
    preview: 'Preview',
    error: 'Recognition failure: subtitle not recognized'
  },
  monitor: {
    speed: 'speed',
    particleTitle: 'Particle trajectory setting',
    information: 'Click the left mouse button in the area of the screen to automatically record the particle movement track, click again to stop recording',
    commit: 'Affirm',
    reset: 'Reset',
    selectedDuration: 'Selected Duration',
    cutAndPreview: 'Cut And Preview',
    audioPreview: 'Audio Preview',
    imagePreview: 'Image Preview',
    scaleMark: 'Scale Mark',
    confirm: 'Confirm',
    cancel: 'Cancel',
    setEntryPoint: 'Set Entry Point',
    setPointOut: 'Set Point Out',
    preview: 'preview',
    backEntryPoint: 'Back Entry Point',
    backPointOut: 'Back Point Out',
    revoke: 'Revoke',
    generateClip: 'Generate Clip',
    videoTag: {
      videoTag: 'Video Tag',
      objectTag: 'Object Tag',
      peopleTag: 'People Tag',
      envTag: 'Env Tag',
      imageDef: 'Image Def',
      specialDef: 'Special Def',
      matchObjects: '{msg} matched',
      noDataHint: 'Label content not identified',
      startReco: 'Start to Recognize',
      imageRate: 'Appearance rate',
      tagRate: 'Occurrence rate',
      imageConf: 'Similarity',
      tagConf: 'Confidence level',
      faceNumber: 'Face number',
      recoType: 'Recognize contents',
      confirmReco: 'Confirm',
      audioReco: 'Recognize audio',
      videoReco: 'Recognize video',
      recoFail: 'Recognize Fail',
      recoDoing: 'Recognizing'
    }
  },
  vumeter: {
    volume: 'Volume',
    dbLimit: 'Threshold limit',
    placeholder: 'Threshold range: -60~0, please enter a positive integer',
    errorTip: 'Please enter the correct range of values',
    noticeTip:'This operation only limits the audio volume of the current timeline, and the newly added video and audio clips are not affected. It is recommended to perform an operation before each video export.'
  },
  mediaResource: {
    hint1: 'Click to upload',
    hint2: 'Please upload the video or audio file you want to clip',
    multiSelectHint: 'Currently in multi selection status, please cancel and execute the following operation',
    btnCreateFolder: 'New Folder',
    btnMultiSelect: 'MultiSelect',
    mediaItemTextCut: 'Cut',
    mediaItemTextPreview: 'Preview',
    mediaItemTextNotTranscode: 'Not Transcoded',
    sort: {
      updateTime: 'updateTime',
      transcodeStatus: 'transcodeStatus',
      asc: 'ASC',
      desc: 'DESC',
    },
    table: {
      filename: 'filename',
      type: 'type',
      format: 'format',
      size: 'size',
      updateTime: 'updateTime',
      duration: 'duration',
      status: 'status',
      operation: 'operatin'
    },
    toCoverTip: 'To Cover Mode',
    toDetailTip: 'To Detail Mode',
    mediaPro: 'Project Media',
    mediaMy: 'My Media',
    mediaPublic: 'Media Library',
    split: 'Split',
  },
  mediaEditPanel: {
    AddPoint: 'Add Point',
    DeletePoint: 'Delete Point',
    Crop: 'Crop',
    BasicSettings: 'Basic Settings',
    CaptionStyle: 'Caption Style',
    tags: 'Tags',
    GroupCaptionSettings: 'Compound Caption Settings',
    GroupCaption: 'Compound Caption',
    Filter: 'Filter',
    ColorCorrection: 'Color Correction',
    Audio: 'Audio',
    Zoom: 'Zoom',
    zoomHeight: "Height",
    zoomWidth: 'Width',
    zoomTips: 'Select and Uniform Scale',
    Position: 'Position',
    Mask: 'Mask',
    Speed: 'Speed',
    Transparency: 'Transparency',
    BackgroundModule: 'Background Module',
    Reset: 'Reset',
    Resetkx: 'Reset Master Keyer',
    RotationAdjustment: 'Rotation Adjustment',
    Overturn: 'Overturn',
    IntelligentColourCorrection: 'Color correction',
    IntelligentAudioDenoise: 'audio noise reduction',
    on: 'On',
    off: 'Off',
    Exposure: 'Exposure',
    Highlight: 'Highlight',
    Shadow: 'Shadow',
    Brightness: 'Brightness',
    Contrast: 'Contrast',
    Blackpoint: 'Black Point',
    Saturation: 'Saturation',
    Vibrance: 'Vibrance',
    Tint: 'Tint',
    Temperature: 'Temperature',
    Vignetting: 'Vignetting',
    Sharpness: 'Sharpness',
    Amount: 'Amount',
    Definition: 'Definition',
    Denoise: 'Image noise removal',
    VolumeControl: 'Volume Control',
    FadeIn: 'Fade In',
    FadeOut: 'Fade Out',
    denoise: 'The audio noise reduction',
    second: 's',
    original: 'Original',
    cartoon: 'The comic book',
    inkPainting: 'Ink painting',
    monochrome: 'Single',
    pen: 'Pen',
    spill: 'Spill Removal Intensity',
    tip: 'On the monitor, select the area to be clipped',
    tip2: 'Please first matting the image on the solid color background before uploading the material content',
    keying: 'Master Keyer',
    changeSpeed: 'Animation self-adaption',
    animation: 'Animation',
    region: 'Region',
    blendingMode: 'Blending Mode',
    blendingModeOption: {
      normal: 'Normal',
      multiply: 'Multiply',
      subtract: 'Subtract',
      screen: 'Screen',
      add: 'Add',
      exclusion: 'Exclusion',
      darken: 'Darken',
      burn: 'Burn',
      linearburn: 'Linear Burn',
      lighten: 'Lighten',
      dodge: 'Dodge',
      overlay: 'Overlay',
      softlight: 'Softlight',
      hardlight: 'Hardlight',
      vividlight: 'Vivid Light',
      pinlight: 'Pin Light',
      linearlight: 'Linear Light',
      hardmix: 'Hard Mix',
      difference: 'Difference'
    },
    uploadFontFamily: '+Upload Font Family',
    transformMode: 'Transform Mode',
    transformModeOption: {
      envelope: 'Envelope',
      crop: 'Crop',
      stretch: 'Stretch'
    },
    timesTheSpeed: 'Times the speed',
    denoise: 'Denoise',
    denoiseLevel: 'Denoise Level',
  },
  themePanel: {
    CaptionsStyle: 'Edit Theme',
    TitleCaptions: 'Title Captions',
    Font: 'Font Family And Size',
    FontColor: 'Font Color',
    trailerCaptions: 'Trailer Captions',
    changeCaptions: 'Change Your Captions',
    ThemeVolume: 'Theme Volume'
  },
  captionEditPanel: {
    Hint: 'Enter Caption Content Here',
    BlockPosition: 'Block Position',
    applyStyle: 'Apply the style',
    Content: 'Content',
    BouncingCaptions: 'Bouncing Captions',
    Style: 'Style',
    EnterManually: 'Enter Manually',
    VoicetoCaptions: 'Voice to Captions',
    ImportSRTCaptions: 'Import SRT Captions',
    enterOrCopy: 'Please Enter Or Copy Text',
    textEnterLine: '*Please use the “Enter” key to feed line into the text',
    blankTimeSKey: '*Please confirm the start and end time of the caption bar through the “space” key on the keyboard and the “S” on the keyboard is the playback shortcut key',
    AddaLine: 'Add a Line',
    EnterBlankLine: 'Enter Blank Line',
    AutomaticTextWrap: 'Automatic Text Wrap',
    Cancel: 'Cancel',
    Clear: 'Clear',
    colorClear: 'Clear',
    colorConfirm: 'Confirm',
    NextStep: 'Next Step',
    Revoke: 'Revoke',
    Finish: 'Finish',
    TextColor: 'Text Color',
    TextFont: 'Text Font',
    Alignment: 'Alignment',
    caption: "Caption",
    alignWay: "Align Way",
    letterSpacing: "Letter Spacing",
    lineSpacing: "Line Spacing",
    coordinate: "Coordinate",
    rotation: "Rotation",
    scale: "Scale",
    shadow: "Shadow",
    color: "Color",
    feather: "Feather",
    offset: "Offset",
    outline: "Outline",
    alpha: "alpha",
    background: "background",
    radius: "Radius",
    Stroke: 'Stroke',
    Width: 'Width',
    Position: 'Position',
    BottomClearance: 'Bottom Clearance',
    testBouncingCaptions: 'Examples Caption',
    CombinationSubtitles: 'Combination',
    selects: 'Select',
    MoreColor: 'More Color...',
    richtextTip: 'Detect the change of content, lick the sync button',
    richtextTab: 'Rich Text',
    ph_RichText: 'Please input content, press enter to make a new line.',
    tip: 'tip',
    cover: 'cover',
    insert: 'insert',
    tipMessage: 'The system has detected that there is a fragment at the current insertion position',
    tipMessage2: 'The font suffix is not right',
    tipMessage3: 'Font has uploaded successfully',
    tipMessage4: 'Font uploaded failed',
    defaultText: 'Default'
  },
  stickerEditPanel: {
    PositionAdjustment: 'Position Adjustment',
    RotationAdjustment: 'Rotation Adjustment'
  },
  musicEditPanel: {
    AudioEditor: 'AudioEditor',
    AudioSpecialEffects: 'Audio Special Effects',
    VolumeControl: 'Volume',
    FadeIn: 'Fade In',
    FadeOut: 'Fade Out',
    None: 'None',
    FastCartoonVoice: 'Fast Cartoon Voice',
    AudioReverb: 'Audio Reverb',
    AudioWahwah: 'Audio Wahwah',
    FemaleVoice: 'Female Voice',
    MaleVoice: 'Male Voice',
    CartoonVoice: 'Cartoon Voice',
    AudioEcho: 'Audio Echo',
    MonsterVoice: 'Monster Voice'
  },
  transitionEditPanel: {
    Duration: 'Duration',
    durationSet: 'Duration Set',
    applyAll: 'Apply to All'
  },
  filterEditPanel: {
    ParameterAdjustment: 'Parameter Adjustment',
    Strength: 'Strength'
  },
  particleEditPanel: {
    ParameterAdjustment: 'Parameter Adjustment',
    Quantity: 'Quantity',
    Size: 'Size',
    MotionTrackingSettings: 'Motion Tracking Settings'
  },
  regulateEditPanel: {
    title: "Color Correction",
    regulate: "Custom Color Correction",
    regulateEditor: 'Color Correction',
    editorArgs: {
      Temperature: 'Temperature',
      Tint: 'Tint',
      Saturation: 'Saturation',
      Brightness: 'Brightness',
      Contrast: 'Contrast',
      Highlight: 'Highlight',
      Shadow: 'Shadow',
      Blackpoint: 'Black Point',
      Amount: 'Amount',
      Vignetting: "Vignetting",
    }
  },
  effectEditPanel: {
    Shape: 'Shape',
    Reverse: 'Reverse',
    ellipse: 'ellipse',
    rect: 'rect',
    EffectEditor: 'Effect Editor',
    Blurry: 'Blurry',
    Mosaic: 'Mosaic',
    Mask: 'Mask',
    PositionAdjustment: 'Position Adjustment',
    Strength: 'Strength',
    Feather: 'Feather',
    Direction: 'Direction',
    Size: 'Size',
    Width: 'Width',
    Height: 'Height',
    KeyFrame: 'KeyFrame',
    Reset: 'Reset'
  },
  compound: {
    videoDuration: 'Video Duration',
    videoFileSize: 'Video File Size',
    outputEffects: 'Output Effects',
    outputModel: "Output Model",
    outputModelOption: {
      all: "All",
      part: "Part"
    },
    scene: 'Scene',
    low: 'Low',
    mid: 'Mid',
    high: 'High',
    scanMode: 'Scan Mode',
    fps: 'FPS',
    outputType: 'Output Type',
    progressive: 'Progressive',
    interlace: 'Interlace',
    encoderType: 'Encoder Type',
    videoBitrateGrade: 'Code Rate',
    sizeLevel: 'Size Level',
    newMedia: 'New Media',
    broadcast: 'Broadcast',
    teletorium: 'Teletorium',
    compileVideo: 'Video Settings',
    compileGif: 'GIF Settings',
    name: 'Name',
    nameHint: 'Input Name',
    chineseNameHint: 'Input Chinese Name',
    englishNameHint: 'Input English Name',
    noCaption: 'No Caption',
    watermark: 'Watermark',
    outputSet: 'Output Settings',
    PureAudio: 'Pure Audio',
    issuePlatform: 'Issue Platform', // 1
    issueDes: 'Bind the following platform account, and release the whole platform with one key', // 1
    preview: 'Composition Preview',
    cover: 'Cover',
    getCover: 'Add Cover',
    localImage: 'Upload Image',
    startTime: 'Start Time',
    spaceTime: 'Duration',
    spaceMaxTime: 'Support up to 15 seconds',
    selectSize: 'Size',
    fluency: 'Fluency',
    fluencyFps: 'Frames / Second',
    commit: 'Export Video',
    cancel: 'Cancel',
    content: 'Content',
    tag: 'Tag',
    contentMsg: 'The more accurate the description is, the easier it is to reach the interested people'
  },
  task: {
    upload: 'Upload',
    transcode: 'Transcode',
    compile: 'Compile',
    uploadFinish: 'Upload Finish',
    compileFinish: 'Compile Finish',
    uploading: 'Uploading',
    compiling: 'Compiling',
    transcodeing: 'Transcodeing',
    delete: 'Delete',
    uploadFailed: 'Upload Failed',
    compileFailed: 'Compile Failed',
    transcodeFailed: 'transcode Failed',
    noTask: 'No Task',
    ai: 'Distinguish',
    aiRunning: 'Identifying',
    failed: 'Distinguish Failed',
    canceled: 'Operation Canceled',
    aiFinish: 'Distinguish Finish',
    see: 'See'
  },
  key: {
    previewPause: 'Preview/Pause',
    delete: 'Delete',
    cut: 'Cut',
    forwardBackward1: 'Forward/Backward (Frame by Frame)',
    forwardBackward2: 'Forward/Backward（Beginning and Ending Clip)）',
    jumpStart: 'Jump to the Beginning',
    jumpEnd: 'Jump to the End',
    rewindPlayback: 'Rewind Playback',
    fastPlayback: 'Fast Forward Playback',
    setInPoint: 'Set Entry Point',
    setOutPoint: 'Set Exit Point',
    shrinkTimeline: 'Shrink Timeline',
    expandTimeline: 'Expand Timeline',
    revoke: 'Revoke',
    save: 'Save',
    insert: 'Insert Clip',
    mouseLeft: 'Mouse Left',
    selectAll: 'Select All',
    forwardOneFrame: 'Forward One Frame',
    backOneFrame: 'Back One Frame',
    screenCut: 'Screen Cut'
  },
  browser: {
    equipmentTitle: 'Your system does not support us at this time, please use the Chrome browser in your system settings',
    equipmentSubTitle: ' Supported systems：',
    Windows: 'Windows',
    Mac: 'macOS',
    Linux: 'Linux',
    browserTitle: 'The current browser is not supported at this time, the following browsers are recommended',
    browserSubTitle: 'Supported browsers：',
    Chrome: 'Chrome',
    ChromeSub: 'Google',
    Firefox: 'Firefox',
    FirefoxSub: 'Firefox',
    Safari: 'Safari',
    SafariSub: 'SafariSub',
    _360: '360',
    _360Sub: '360',
    _360Cut: ',And switch to speed mode',
    Opera: 'Opera',
    OperaSub: 'Opera',
    Edge: 'Edge',
    EdgeSub: 'Edge',
    updateTitle: ' The current browser version is not supported, please upgrade to the latest ',
    browser: ' browser',
    updateSubTitle: 'Brings more safety, speed, and fun',
    recommendChrome: 'It is recommended that you use the latest version of the Google browser',
    recommendNo: 'If you do not want to use the recommended Google browser for the time being',
    paramsTitle: 'The browser needs to set the following options to work, please follow the steps according to the browser version, refresh the page after completion',
    noSupport: 'Doesn’t support ',
    updateModeTitle: "Not supported in privacy mode"
  },
  do: {
    revoke: 'Revoke',
    recover: 'Recover',
    cut: 'Cut',
    delete: 'Delete',
    freezeframe: 'Freeze Frame',
    AudioVideoSeparation: 'Audio video separation',
    resumeAudioVideoSeparation: 'Resume Audio',
    monoSeparation: 'Mono Separation',
    videoTrack: 'Video Track',
    audioTrack: 'Audio Track',
    captionTrack: 'Caption Track',
    compoundCaptionTrack: 'Compound Caption Track',
    stickerTrack: 'Sticker Track',
    musicLyricsTrack: 'Music Lyrics Track',
    timelineVideoFxTrack: 'Video FX Track',
    Mute: 'Mute',
    Volume: 'Volume',
    Show: 'Show',
    NoShow: 'No Show',
    AddSubtitle: 'Add Subtitle',
    addTrack: 'Add Track',
    editTheme: 'Edit Theme',
    audioToText: 'AI Libretto Captions',
    alignment: 'Video Synchronization',
    play: 'Play',
    pause: 'Pause',
    enableSound: 'Enable Sound',
    showScreen: 'Show Screen',
    imageGrab: 'Screen Shot',
    imageGrabMsg: 'Screen Shot Completed,Please Check in 【My Videos】',
    showSection: 'Show Section',
    reverse: 'Reverse',
    cancelReverse: 'Cancel Reverse',
    copy: 'Copy',
    createCombination: 'Create Combination',
    removeCombination: 'Remove Combination',
    screenCut: 'Screen Cut',
    replaceClip: 'Replace Clip',
    goThumbnailAll: 'Show all thumbnail',
    goThumbnailOne: 'Show one thumbnail'
  },
  liveEditor: {
    soundChannel: {
      stereo: 'Stereo',
      mono: 'Mono',
    },
    dotDuration: "Duration",
    videoPreview: 'Video Preview',
    bjTime: 'Beijing Time',
    generateClip: 'Generate Clip',
    liveSignal: 'Live Signal',
    deleteClip: 'Delete Clip',
    carefulEdit: 'Careful Edit',
    clipList: 'Clip List',
    createLiveEditor: 'Create Live Editor',
    liveUrl: 'Live Url',
    resolvingPower: 'Resolving Power',
    selectPower: 'Please Select Resolving Power',
    liveEdit: 'Live Editor',
    liveUrlMsg: 'Please Input Live Url',
    streamFailed: 'Live signal connection failed, please check the live address',
    des: 'Record live broadcast, instantly disassemble highlights',
    sorketError: 'Service connection failed to get updates',
    reconnection: 'Reconnection',
    connecting: 'Establishing connection with server',
    connected: 'Server connection successful',
    publish: 'Publish',
    publishSuccess: 'Publish Success',
    stopUpdating: 'Stop Update',
    recoverUpdating: 'Continue Update',
    stopMsg: 'Success',
    stopMsgErr: 'Fail',
    published: 'Published',
    duration: 'Clip Duration',
    ph_clip_search: 'Search',
    time: 'Time',
    text: 'Text',
    downloadCaption: 'Download Caption',
    captionFormat: 'Caption Format',
    msgStartTimeNotRight: 'Start Time is not right',
    fineCutVideo: 'Fine Cut Video'
  },
  split: {
    splitEdit: 'Video Splitting',
    des: 'Intelligent generation of multiple pieces of content',
    clipChangedItemDeleted: 'Clip has been removed together with record',
    ph_clip_search: 'Search for name',
    ph_onebest: 'Video clip ',
    create: {
      createSplitEditor: 'Create split project',
      selectMaterial: 'Select material',
      name: 'Name',
      ph_name: 'Please input name, press enter to submit',
      validName: 'Name is required',
      sceneSelect: 'Select scene',
      clipSelect: 'Select where',
      submit: 'Confirm',
      cancel: 'Cancel',
      tip1: 'Click or drag file to upload',
      tip2: 'Supported extensions：mp4、mov、avi、mpeg、wmv...',
      tip3: 'Material Center select',
      materialFromOnly: 'You have selected one',
      noMaterialSelect: 'No material selected',
      closeHintWhenUploading: 'Sure to close?',
      uploadingNotClose: 'Cannot close when uploading'
    }
  },
  vanchor: {
    title: 'Virtual anchor',
    des: 'Use virtual anchor to intelligently generate video'
  },
  smartColorCorrection: {
    title: 'Smart color correction',
    des: 'Simple function and professinal color correction'
  },
  smartClip: {
    title: 'Smart clip',
    des: 'One click generate vlog'
  },
  audioRecord: {
    generate: 'Generate a recording file',
    preview: 'At the same time to preview',
    mute: 'Preview the mute',
    selectClip: 'Select the fragment you want to replace'
  },
  smartRecording: {
    title: 'Smart Recording',
    des: 'Beauty beauty, intelligent portrait background segmentation,AR real-time rendering effects',
    setting: "set",
    createDialogForm: {
      title: 'select project ratio',
      ratio: "Ratio",
      confirm: "Confirm",
      versionHint: 'Browser version: Chrome 94 or later',
      versionHintMessage: 'The current browser does not support this function. Check the browser version'
    },
    controlMenuBar: {
      resolution: "resolution",
      resolutionTip: 'The resolution range depends on the current camera hardware parameters. The system supports a maximum of 1080P',
      resolutionPlaceholder: "select resolution",
      back: 'back',
      audioInputPlaceholder: "select microphone",
      videoInputPlaceholder: "select camera",
      resetRecording: "reset Recording",
      edit: "edit",
      download: "download",
      videoMirror: "video Mirror"
    },
    beauty: {
      title: 'beauty',
      switchTitle: "effect switch",
    },
    filter: {
      title: "filter"
    },
    bgBlur: {
      title: "background blur",
      none: "none"
    },
    ARScene: {
      title: "AR Scene",
      none: "none",
      bgBlur: "background Blur"
    },
    testData: {
      frameTime: 'Frame Time',
      maxTime: 'Max Time',
      minTime: 'Min Time',
      packetLoss: 'Packet Loss'
    }
  },
  textVideo: {
    title: 'Text video',
    des: "Edit your video with text and break the tradition",
    tag: "Text video",
    clipChangedItemDeleted: "Clip has been removed together with record",
    ph_clip_search: "Search for name",
    ph_onebest: "Video clip ",
    edit: {
      title: 'Edit Text',
      confirm: 'Confirm',
      cancel: 'Cancel',
      hint1: 'You can only modify a single paragraph of content'
    },
    create: {
      createTextVideoItem: "Create a text video project",
      selectMaterial: "Select material",
      name: "Name",
      ph_name: "Please input name, press enter to submit",
      validName: "Name is required",
      submit: "Confirm",
      cancel: "Cancel",
      audioNoiseReduction: "Audio noise reduction",
      tip1: "Click or drag file to upload",
      tip2: "Supported extensions：mp4、mov、avi、mpeg、wmv...",
      tip3: "Please post a video of multiple slots",
      tip4: "Material Center select",
      tip5: "Public Source",
      materialFromOnly: "You have selected one",
      noMaterialSelect: "No material selected",
      closeHintWhenUploading: "Sure to close?",
      deleteUploading: "The current material is in uploading, please do it after uploading",
      removeFileHint: "Sure to remove",
      removeFileTitle: "prompt",
      uploadingNotClose: "Cannot close when uploading",
      materialIsNull: 'Material selection failed. Please try again',
      // maxUploadNum: "Upload up to three videos, please reselect",
      maxUploadNum: "Only one video can be uploaded. Please choose again",
      maxMaterialSelect: "Choose up to three videos",
      uploadHead: {
        name: 'Filename',
        progress: 'Upload progress',
        status: 'Status',
        operation: 'Operation',
      },
      jobStatus: {
        doing: 'Uploading',
        done: 'Success',
        fail: 'Fail'
      }
    },
    main: {
      logoTitle: 'Intelligent Text Video Editing System',
      impYunJian: 'import Cloud-Based video',
      saveProject: 'save project',
      impVideo: 'import video',
    },
    clip: {
      headerTip: 'Upload pictures or videos to the opening titles, 16:9 ratio is preferred',
      footerTip: 'Upload pictures or videos to the end, 16:9 ratio is preferred'
    },
    template: {
      caption: [{
        title: 'Main title content',
        font: 'Font style',
        color: 'Font color'
      },
      {
        title: 'Subtitle content',
        font: 'Font style',
        color: 'Font color'
      }],
      upload: {
        uploadBtn: 'Upload data',
        uploadFailBtn: 'Retry',
        replaceBtn: 'Replace',
        tip: 'Upload pictures or videos into the window with a 16:9 ratio',
        volume: 'Material volume'
      }
    },
    msg: {
      hint1: 'The last video segment cannot be deleted',
      hint2: 'Delete area contains the template already made, please delete the template and try again!',
      hasTemplate: 'The selection contains the template that has been set, please reselect!',
      invalid: 'Invalid operation!',
      MXFFileLengthLimit: 'MXF file number has limitation of 9!',
      replaceClipDurationError: 'The Current Video Is Shorter Than The Length Of The Meta Clip And Cannot Be Replaced',
    },
    mainStand: 'Main',
    projectName: 'Project Name',
    textOverview: 'Text Overview',
    videoPreview: 'Video Preview',
    videoList: 'Video List',
    captionSetting: 'Compound Caption Settings',
    librettoStyle: 'Libretto Caption Settings',
    clipHeader: 'Video head',
    clipFooter: 'Video tail',
    head: 'Header',
    foot: 'Footer',
    other: 'Other'
  },
  coarse: {
    coarseEdit: 'Coarse Edit',
    leftTopTitle: 'Video Edit',
    tlWindowTitle: 'Source Video',
    monitorWindowTitle: 'Editing Clip Video',
    duration: 'Duration',
    clipList: 'Clip List',
    deleteClip: 'Delete Clip',
    previewClips: 'Preview Selected Clips',
    saveSeparate: 'Save Separate',
    saveWhole: 'Save Whole',
    saveSeperateError: 'Please generate clips first',
    saveWholeError: 'Please select clips first',
    fineCutVideo: 'Fine Cut Video'
  },
  template: {
    title: 'Find Your Favorite Template',
    msg: 'Massive Design Templates Covering Hundreds Of Design Scenarios',
    classify: 'Classify',
    screen: 'Screen',
    all: 'All',
    self: 'Personal',
    placeholder: 'Enter Keyword Search Template',
    search: 'Please enter keywords to search',
    searchTag: 'Please enter the tag name to search',
    temp: 'Template',
    audio: 'Audio',
    video: 'Video',
    image: 'Image',
    'video/image': 'Video Or Image',
    edit: 'Use Cloud-Based Editor',
    import: 'Import',
    captionMsg: 'Enter The Text And Press Enter To Change The Subtitle',
    short: 'The Duration Of The Material Is Too Short',
    exportTemplate: 'Export Template',
    makeTemplate: 'Make Template',
    makeTemplateHint: 'The package size should not be more than 200MB, please compress the package',
    templateDuration: 'Template Duration',
    templateSize: 'Template Size',
    templateSizeHint: ' | Should not be more than 200MB',
    templateSizeHint2: 'Calculating...',
    templateSizeHint3: '(predicted)',
    templateCount: 'Template Count',
    supportFrameRatio: 'Support Frame Ratio',
    frameRatio: 'Frame Ratio',
    canReplace: 'Can Replace',
    generateMsg: 'There must be at least one replaceable fragment',
    fps: 'FPS',
    preview: 'Preview',
    exactTag: 'Exact Tag'
  },
  publish: {
    wechart: 'Wechart',
    xinlang: 'XinLang',
    kuaishou: 'KuaiShou',
    xinhua: 'XinHuaShe',
    manage: 'Management Center',
    noBindAccount1: 'No account has been bound. Please go to ',
    noBindAccount2: ' channel',
    AccountBinding: 'Account Binding',
    bind: 'Bind',
    unbind: 'Unbind',
    status: 'Normal',
    selectPlatform: 'Select Platform',
    unbindMsg: 'After unbinding, you will not be able to use this account to publish videos',
    accountManagement: 'Account',
    dataManagement: 'Data',
    viewCounts: 'Views in recent * days',
    likeCounts: 'Likes in recent * days',
    forwardCounts: 'Forward in recent * days',
    commentCounts: 'Comment in recent * days',
    yesterday: 'Last 1 day',
    nearly7: 'Last 7 days',
    nearly14: 'Last 14 days',
    nearly30: 'Last 30 days',
    authSuccess: 'Authorization successful',
    authFail: 'Authorization failed',
    unbindSuccess: 'Unbind successfully',
    data: 'Platform Data',
    user: 'User',
    num: 'Num',
    platform: 'Platform',
    bindSuccess: 'Bind Success',
    bindFailed: 'Bind Failed'
  },
  msg: {
    copySuccess: 'Copy Success',
    NameEmpty: 'Please enter a name',
    ImageEmpty: 'Please select a cover',
    delPieces: 'You have not yet selected the material to delete',
    saveSuccess: 'Save Success',
    submitSuccess: 'Sunmitted Successfully',
    saveFailed: 'Save Failed',
    saving: 'Saving...',
    compoundSuccess: 'Compound successful, upload progress can be checked in the task',
    invalidUrl: 'Invalid url',
    loadModulesFailed: 'Loading Failed',
    createFailed: 'Create Failed',
    readyJump: 'Ready to jump...',
    addProject: 'Add to project material',
    changeResolution: 'Switch Frames',
    copyMsg: 'You are about to switch to an empty frame. Do you want to copy the contents of the ',
    copyMsg2: ' timeline',
    copyResolution: 'Copy',
    createResolution: 'Create',
    isUploadList: 'Upload according to serialization file',
    uploadList: 'Serialization Upload',
    upload: 'Upload',
    captionMsg: 'The generated subtitles will cover the original subtitles. Do you want to continue?',
    generateLibretto: 'Generating Subtitles',
    cover: 'Cover',
    insert: 'Insert',
    alignMsg: 'This operation will calibrate the position of the video clip according to the audio synchronization. Do you want to continue?',
    deleteAllScaleKeyFrame: 'Sure to delete all scale key frame?',
    deleteAllTransKeyFrame: 'Sure to delete all translate key frame?',
    deleteAllOpacityKeyFrame: 'Sure to delete all opacity key frame?',
    deleteAllRotationKeyFrame: 'Sure to delete all rotation key frame?',
    deleteAllMaskKeyFrame: 'Sure to delete all mask key frame?',
    cutMsg: 'Operate the area to crop on the monitor',
    replaceMsg: 'Please select the material you want to replace',
    addToTrack: 'Add to track',
    deleteResource: 'Delete resource',
    retryResource: 'Retry',
    uploadFullMse: 'Unable to upload, please go to ',
    delete: ' to delete',
    msg: 'Tips',
    deleteMedia: 'This operation will remove the material from the item list. Do you want to continue',
    addToTranscode: 'Start transcode',
    deleteResource: 'Delete resource',
    applySuccess: 'Apply Success',
    applyError: 'Apply Error',
    reverseSuccess: 'Reverse Success',
    reverseError: 'Reverse Error',
    reverseWaiting: 'Reverse Waiting',
    copyTip: 'Multiple replication is not supported, please select again!',
    inOperation: 'in operation',
    hint: 'Hint',
    pasteTipMessage: 'The system detects an existing fragment at the current paste position',
    templateCombinationMessage: 'Combinations must be replaceable, uniform replacement type and have the same reverse value'
  },
  dialog: {
    finish: 'Finish',
    confirm: 'Confirm',
    cancel: 'Cancel',
    delPro: 'Do you want to delete this item?',
    delPieces: 'Do you want to delete this movie'
  },
  loading: {
    wasm: 'Load modules...',
    font: 'Install font...',
    importSrt: 'Import Srt File...',
    alignment: 'Video Aligning...',
    loading: 'Loading...',
    richCaption: 'Build Caption...',
    handling: 'Handling',
    savingNoOtherOperation: 'Saving, do not close current page...',
    returnToEdit: 'Return to Edit',
    saveCompelete: 'Complete!',
    jumpPage: 'Click to Save',
    installing: 'installing...',
    audioAnalysing: 'Audio analysing...'
  },
  font: {
    SourceHanSansCN: 'Source Han Sans CN',
    font1: 'Font 1',
    font2: 'Font 2',
    font3: 'Font 3',
    font4: 'Font 4',
    font5: 'Font 5'
  },
  controlbar: {
    jumpStart: 'Move to the Head',
    jumpEnd: 'Move to the Tail',
    head5Frame: '5 Frames Forward',
    tail5Frame: '5 Frames Backward',
    safebox: 'Safe Box'
  },
  labels: {
    ph_keyword: 'Type keyword to search',
    delete: 'DELETE',
    templateListHint: 'Clips marked with the same color will be automatically filled with the same content',
    all: 'All',
    isUsed: 'IsUsed',
    defaultFolderName: 'New Folder',
    export: 'EXPORT',
    create: 'CREATE',
    addKeyframe: 'Add Keyframe',
    waiting: 'Waiting',
    missing: 'Missing',
    comingSoon: 'Coming Soon',
    noData: 'No Data',
    loadingData: 'Loading...',
    getVerifyCode: 'Get Code',
    hasSent: 'Sent Successfully · ',
    purchased: 'Purchased',
    none: 'NONE',
    edit: 'Edit',
    checked: 'Checked',
    applyStyle: 'Apply the style',
    confirm: 'Confirm',
    iknow: 'I know',
    download: 'Download',
    combination: 'Combination',
    cancel: 'CANCEL',
    select: 'Please select',
    unknown: 'Unknown',
    onTrack: 'On Track',
    delete: 'Delete',
    checkedTip: '1 material has been selected',
    combination: 'Combination',
    noCombination: 'No combination',
    chooseAClip: 'Choose a clip',
    replaceType: 'Replace Type',
    modify: 'Modify'
  },
  notPurchased: {
    list: 'The following materials were not purchased',
    name: 'Material Name',
    caption: 'Caption',
    sticker: 'Sticker',
    music: 'Music',
    theme: 'Theme',
    transition: 'Transition',
    filter: 'Filter',
    particle: 'Particle',
    fx: 'fx',
    template: 'Template',
    compoundCaption: 'Compound Caption',
    msg: 'This template contains the following unpurchased materials, please submit them to the background of American Photography materials',
    submit: 'Submit Master Account'
  },
  components: {
    tagSelect: {
      addTagHint1: 'Add a tag',
      addTagHint2: 'Select the tag below / enter the tag manually and press the space to confirm'
    },
    dialogTrimUpload: {
      title: 'Upload with trim'
    },
    replaceClipModal: {
      title: 'Replace Clip',
      confirm: 'Replace Clip',
      cancel: 'Cancel'
    },
    dialogSaveAs: {
      title: 'Save As...',
      label: 'New Project Name',
      ph_name: 'Input your new project name',
      successMessage: 'Project clone successfully',
      failMessage: 'Project clone failed'
    },
    dialogRename: {
      title: 'New Folder',
      title2: 'Rename',
      label: 'Folder name',
      ph_name: 'Folder name'
    },
    dialogChangelog: {
      title: 'Version Change'
    },
    dialogFontfamily: {
      title: 'Disclaimers'
    },
    dialogNotPurchase: {
      title: 'The following materials were not purchased',
      name: 'Material Name',
      hint: 'This template contains the following copyrighted materials, please submit to the aesthetic photography special effects material creation platform, the main account for purchase operation',
      submit: 'Submit',
      successMessage: 'Submit Successful',
      failMessage: 'Submit Failed'
    },
    dialogExportProjectXml: {
      title: 'This operation will export the project xml, continue?'
    },
    animaitonSlider: {
      animationDuration: 'Animation Duration',
      animationPeriod: 'Animation Period'
    }
  }
}

export default en
