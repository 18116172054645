import { render, staticRenderFns } from "./SvgIcon.vue?vue&type=template&id=7d4f4e49&scoped=true&"
import script from "./SvgIcon.vue?vue&type=script&lang=js&"
export * from "./SvgIcon.vue?vue&type=script&lang=js&"
import style0 from "./SvgIcon.vue?vue&type=style&index=0&id=7d4f4e49&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d4f4e49",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/NvBsVue-custom-henantai/NvBsVue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7d4f4e49')) {
      api.createRecord('7d4f4e49', component.options)
    } else {
      api.reload('7d4f4e49', component.options)
    }
    module.hot.accept("./SvgIcon.vue?vue&type=template&id=7d4f4e49&scoped=true&", function () {
      api.rerender('7d4f4e49', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/SvgIcon.vue"
export default component.exports