import TimelineUtils from './TimelineUtils'
import GL from './Global'
import { getSecondDurationByWidth, getWidthByDuration2, getWidthByDuration } from './TimelineRulerUtils'
import EventBus from '../EventBus'
import EventBusKey from './EventBusKey'
import cloneDeepWith from 'lodash/cloneDeepWith'
import Utils from './Utils'
import { buildFormatedThumbnailNumber, getKeyFramesByClip, refreshVideoPropertyFxWhenMouseUp } from '@/utils'
import NvAudioWaveManager from '@/api/NvAudioWaveManager'
import store from '@/store'

// 每次拖动时记录videoClip的top 用以判断松手时在哪个轨道
let tracksTop = []
export function setTracksTop (className, fromTransition) {
  let videoTracks = document.getElementsByClassName(className)
  console.log('轨道个数', videoTracks.length)
  tracksTop.length = 0
  if (!fromTransition) {
    let plottingScale = document.getElementById('timeLineArea-rulerContainer')
    // console.log('比例尺的位置：', plottingScale, plottingScale.getBoundingClientRect().top)
    tracksTop.push(plottingScale.getBoundingClientRect().top)
  }
  for (let i = 0; i < videoTracks.length; i++) {
    let div = videoTracks[i]
    tracksTop.push(div.getBoundingClientRect().top)
  }
}

export function getTracksTop () {
  return tracksTop
}

/**
 * 通过Y坐标获取当前鼠标在哪个轨道的位置
 * @param e
 * @param trackType 轨道类型
 * @returns {number} 鼠标在该类型的位置 默认包含比例尺的的1 如果需要和item轨道对应 在获取以后  需要手动减一
 */
export function checkOnWhichTrack (e, trackType) {
  let top = e.clientY
  let trackHeight = Utils.getTrackDOMHeight(trackType)
  for (let i = tracksTop.length - 1; i >= 0; i--) {
    let nowTrackTop = tracksTop[i]
    // console.log('检查拖动轨道位置：', i, top, nowTrackTop)
    // *.5 表示拖到轨道间的缝隙中
    if (Math.abs(top - nowTrackTop) <= 5 && i > 0) {
      return i - 0.5 - 1
    } else if (Math.abs(top - nowTrackTop - trackHeight) <= 5 && i > 0) {
      return i + 0.5 - 1
    }
    if (top >= nowTrackTop) {
      // console.log('被拖到的轨道位置：', i, top, nowTrackTop)
      if (i === 0 && (top > nowTrackTop + trackHeight)) {
        // console.log('不是视频轨道啥也不是')
        continue
      } else if (top > nowTrackTop + trackHeight) {
        // console.log('超出了啥也不是')
        continue
      }
      // console.log('就是你了：  ' + i)
      return i
    }
  }
  return -1
}

/**
 * 获取相应type的轨道索引
 * @param items
 * @param type 轨道track
 * @returns {number}
 */
export function getTrackStartIndexInItems (items, type) {
  let index = 0
  for (let j = 0; j < items.length; j++) {
    let track = items[j]
    if (track.type === type) {
      index = j
      break
    }
  }
  return index
}

/**
 * @return {string}
 */
export function ClipTypeToTrackType (clipType) {
  let trackType
  if (clipType === GL.VIDEOCLIP) {
    trackType = GL.VIDEOTRACK
  } else if (clipType === GL.AUDIOCLIP) {
    trackType = GL.AUDIOTRACK
  } else if (clipType === GL.CAPTIONCLIP) {
    trackType = GL.CAPTIONTRACK
  } else if (clipType === GL.COMPOUNDCAPTIONCLIP) {
    trackType = GL.COMPOUNDCAPTIONTRACK
  } else if (clipType === GL.STICKERCLIP) {
    trackType = GL.STICKERTRACK
  } else if (clipType === GL.TIMELINEVIDEOFX) {
    trackType = GL.TIMELINEVIDEOFXTRACK
  } else if (clipType === GL.MUSICLYRICSCLIP) {
    trackType = GL.MUSICLYRICSTRACK
  }
  return trackType;
}

export function getTrackLastIndexInItems (items, type) {
  let index = -1
  for (let j = items.length - 1; j >= 0; j--) {
    let track = items[j]
    if (track.type === type) {
      index = j
      break
    }
  }
  return index
}

export function getTrackAndClipPositionOnItems (items, timelineClipObj) {
  let index = {
    trackIndex: -1,
    clipIndex: -1
  }
  for (let j = 0; j < items.length; j++) {
    let track = items[j]
    for (let i = 0; i < track.clips.length; i++) {
      if (track.clips[i].raw === timelineClipObj) {
        index.trackIndex = j
        index.clipIndex = i
        return index
      }
    }
  }
  return index
}

// 数据中的轨道index和timeline中的轨道位置转换
export function itemVideoTrackIndexToTimelineTrackIndex (index) {
  let videoTrackStartPositionInItems = getTrackStartIndexInItems(TimelineUtils.timelineData.tracks, GL.VIDEOTRACK)
  // console.log('itemVideoTrackIndexToTimelineTrackIndex', videoTrackStartPositionInItems, TimelineUtils.timeline().videoTrackCount(), index)
  // return getTrackCountByType(GL.VIDEOTRACK) - (index - videoTrackStartPositionInItems)
  return TimelineUtils.timeline().videoTrackCount() - 1 - (index - videoTrackStartPositionInItems)

}

/**
 * 上面的itemVideoTrackIndexToTimelineTrackIndex使用TimelineUtils.timeline().videoTrackCount()获取轨道数量
 * 但是在刚进入项目的时候 生命周期是早于轨道的铺设的  所以这时获取的轨道数量就是0 所以改为使用数据中记载的来
 */
function getTrackCountByType (type) {
  let tracks = TimelineUtils.timelineData.tracks
  let count = 0
  for (let i = 0, length = tracks.length; i < length; i++) {
    let track = tracks[i]
    if (track.type === type) {
      count++
    }
  }
  return count
}

// 数据中的轨道index和timeline中的轨道位置转换
export function itemAudioTrackIndexToTimelineTrackIndex (index) {
  // 减去一个空轨 再减去轨道在items中的起始位置
  let videoTrackStartPositionInItems = getTrackStartIndexInItems(TimelineUtils.timelineData.tracks, GL.AUDIOTRACK)
  return TimelineUtils.timeline().audioTrackCount() - 1 - (index - videoTrackStartPositionInItems)
}

// timeline中的轨道index和数据中的轨道index转换
export function timelineVideoTrackIndexToItemTrackIndex (index) {
  let videoTrackStartPositionInItems = getTrackStartIndexInItems(TimelineUtils.timelineData.tracks, GL.VIDEOTRACK)
  return TimelineUtils.timeline().videoTrackCount() - 1 - index + videoTrackStartPositionInItems
}

/*
对插入后的clip index进行排序
 */
export function bSort (arr) {
  var len = arr.length
  for (var i = 0; i < len - 1; i++) {
    for (var j = 0; j < len - 1 - i; j++) {
      // 相邻元素两两对比，元素交换，大的元素交换到后面
      if (arr[j].inPoint > arr[j + 1].inPoint) {
        var temp = arr[j]
        arr[j] = arr[j + 1]
        arr[j + 1] = temp
      }
    }
  }
  return arr
}

/*
检查clip碰撞
 */
export function checkClipCrash (checkClipDatas, checkClip, needAddClips) {
  let inPoint = 0
  let outPoint = 0
  let newClipData = []
  for (let j = 0; j < checkClipDatas.length; j++) {
    inPoint = checkClipDatas[j].inPoint
    outPoint = checkClipDatas[j].outPoint
    // console.log(' : ' + inPoint + ' outPoint: ' + outPoint + '  checkClip.inPoint: ' + checkClip.inPoint + '  checkClip.outPoint: ' + checkClip.outPoint)
    if (checkClip.outPoint <= inPoint) { // 完全在该clip前面
      // console.log('完全在该clip前面')
      newClipData.push(checkClipDatas[j])
    } else if (checkClip.inPoint >= outPoint) { // 完全在该clip后面
      // console.log('完全在该clip后面')
      newClipData.push(checkClipDatas[j])
    } else if (checkClip.inPoint <= inPoint) { // 前覆盖
      // console.log('前覆盖')
      if (checkClip.outPoint >= outPoint) { // 全覆盖
        // console.log('全覆盖')
        // 判断是否覆盖后面的clip
      } else { // 只覆盖前面了
        // console.log('只覆盖前面了')
        if (checkClipDatas[j].trimIn !== undefined) {
          checkClipDatas[j].trimIn = Math.round(checkClipDatas[j].trimIn + (checkClip.outPoint - checkClipDatas[j].inPoint) * (checkClip.speed || 1))
        }
        const oldInPoint = checkClipDatas[j].inPoint
        checkClipDatas[j].inPoint = checkClip.outPoint
        const diff = checkClipDatas[j].inPoint - oldInPoint
        const frames = getKeyFramesByClip(checkClipDatas[j])
        if (frames.length > 0) {
          frames.forEach(frame => {
            frame.time -= diff
          })
        }
        if (checkClipDatas[j].videoFxs) {
          let propertyFx = checkClipDatas[j].videoFxs.find(fx => fx.type === 'property')
          if (propertyFx) {
            let package2Id = propertyFx.isPostOutAnimation ? 'Post Package2 Id' : 'Package2 Id'
            if (propertyFx.animationType) {
              for (let i = 0; i < propertyFx.params.length; i++) {
                let param = propertyFx.params[i]
                if (param.key === 'Package Effect In' || param.key === 'Package Effect Out') param.value -= diff
              }
            } else {
              const paramPackage2Id = propertyFx.params.find(item => item.key === package2Id)
              if (paramPackage2Id && paramPackage2Id.value) {
                for (let i = 0; i < propertyFx.params.length; i++) {
                  let param = propertyFx.params[i]
                  if (param.key === 'Package2 Effect In' || param.key === 'Package2 Effect Out') param.value -= diff
                }
              }
            }
          }
        }
        refreshVideoPropertyFxWhenMouseUp(checkClipDatas[j])
        newClipData.push(checkClipDatas[j])
      }
    } else if (checkClip.outPoint >= outPoint) { // 后覆盖
      // console.log('后覆盖', checkClipDatas[j].trimOut, checkClipDatas[j].outPoint, checkClip.inPoint)
      if (checkClipDatas[j].trimOut !== undefined) {
        checkClipDatas[j].trimOut = Math.round(checkClipDatas[j].trimOut - (checkClipDatas[j].outPoint - checkClip.inPoint) * (checkClipDatas[j].speed || 1))
      }
      checkClipDatas[j].outPoint = checkClip.inPoint
      newClipData.push(checkClipDatas[j])
    } else if (checkClip.inPoint > inPoint && checkClip.outPoint < outPoint) {
      // console.log('中间覆盖')

      const headClip = deepCopy(checkClipDatas[j])
      headClip.trimOut !== undefined && (headClip.trimOut = Math.round(headClip.trimOut - (headClip.outPoint - checkClip.inPoint) * (checkClipDatas[j].speed || 1)))
      headClip.outPoint = checkClip.inPoint

      const endClip = deepCopy(checkClipDatas[j])
      endClip.trimIn !== undefined && (endClip.trimIn = Math.round(endClip.trimIn + (checkClip.outPoint - endClip.inPoint) * (checkClipDatas[j].speed || 1)))
      endClip.inPoint = checkClip.outPoint
      // 这里的分割会导致uuid的重复，但是在后面要通过这个特性找到后面的clip 并且对分割的视频添加底层不能添加上的特效等东西
      // 所以需要存一下分割前的uuid
      endClip.oldUuid = endClip.uuid
      endClip.uuid = TimelineUtils.uuid()
      newClipData.push(headClip,endClip)
    }
  }
  if (needAddClips) {
    needAddClips.forEach(clip => {
      newClipData.push(clip)
    })
  } else {
    newClipData.push(checkClip)
  }
  newClipData = sortClip(newClipData)
  return newClipData
}

// 检查clip是否和已有轨道有重叠
export function checkHasCrash (checkClip, startIndex, endIndex, trackList) {
  console.log("checkHasCrash", startIndex, endIndex, trackList.length, trackList)
  for (let i = startIndex; i < endIndex + 1; i++) {
    let trackClips = trackList[i]
    let count = trackClips.clips.length
    let inPoint = 0
    let outPoint = 0
    let canAdd = true;
    for (let i = 0; i < count; i++) {
      let clipInTrack = trackClips.clips[i]
      inPoint = clipInTrack.inPoint
      outPoint = clipInTrack.outPoint
      console.log(' : ' + inPoint + ' outPoint: ' + outPoint + '  checkClip.inPoint: ' + checkClip.inPoint + '  checkClip.outPoint: ' + checkClip.outPoint)
      if (checkClip.outPoint <= inPoint) { // 完全在该clip前面
        console.log('完全在该clip前面')
      } else if (checkClip.inPoint >= outPoint) { // 完全在该clip后面
        console.log('完全在该clip后面')
      } else if (checkClip.inPoint <= inPoint) { // 前覆盖
        canAdd =  false
      } else if (checkClip.outPoint >= outPoint) { // 后覆盖
        console.log('后覆盖', clipInTrack.trimOut, clipInTrack.outPoint, checkClip.inPoint)
        canAdd = false
      } else if (checkClip.inPoint > inPoint && checkClip.outPoint < outPoint) {
        console.log('中间截断', clipInTrack)
        canAdd = false
      }
    }
    if (canAdd) {
      return i;
    }
  }
  return -1;
}

export function newDraggingDiv (duration, type) {
  let dragDiv = document.createElement('div')
  dragDiv.className = 'clipItemContainer'
  // 需要根据素材的长度计算 这里先默认20秒
  dragDiv.style.width = getWidthByDuration(duration) + 'px'
  const height = Utils.getTrackDOMHeight(type)
  dragDiv.style.height = height + 'px'
  let childDiv = document.createElement('div')
  childDiv.className = 'clipItem'
  childDiv.style.width = getWidthByDuration(duration) + 'px'
  childDiv.style.height = height + 'px'
  dragDiv.appendChild(childDiv)
  return dragDiv
}

export function newClipOnDragNewClip (draggedClip, inPoint) {
  console.log('newClipOnDragNewClip', draggedClip, inPoint)
  let data = deepCopy(draggedClip.clips)
  data.index = 0
  data.inPoint = inPoint
  data.selected = true
  if (draggedClip.type === GL.VIDEOTRACK) {
    data.outPoint = Math.round(data.inPoint + (data.trimOut - data.trimIn) / data.speed)
  } else if (draggedClip.type === GL.AUDIOTRACK) {
    data.outPoint = data.inPoint + data.trimOut - data.trimIn
  } else if (draggedClip.type === GL.CAPTIONTRACK) {
    if (draggedClip.clips.outPoint === -1) {
      console.log('从列表拖动的时候给个默认时长', draggedClip, inPoint)
      data.outPoint = data.inPoint + GL.CAPTIONDEFAULTLENGTH
    } else {
      data.outPoint = inPoint + (draggedClip.clips.outPoint - draggedClip.clips.inPoint)
    }
  } else if (draggedClip.type === GL.COMPOUNDCAPTIONTRACK) {
    if (draggedClip.clips.outPoint === -1) {
      console.log('从列表拖动的时候给个默认时长', draggedClip, inPoint)
      data.outPoint = data.inPoint + GL.CAPTIONDEFAULTLENGTH
    } else {
      data.outPoint = inPoint + (draggedClip.clips.outPoint - draggedClip.clips.inPoint)
    }
  } else if (draggedClip.type === GL.STICKERTRACK) {
    if (draggedClip.clips.outPoint === -1) {
      console.log('从列表拖动的时候给个默认时长', draggedClip, inPoint)
      data.outPoint = data.inPoint + GL.CAPTIONDEFAULTLENGTH
    } else {
      data.outPoint = inPoint + (draggedClip.clips.outPoint - draggedClip.clips.inPoint)
    }
  } else if (draggedClip.type === GL.TIMELINEVIDEOFXTRACK) {
    if (draggedClip.clips.outPoint === -1) {
      console.log('从列表拖动的时候给个默认时长', draggedClip, inPoint)
      data.outPoint = data.inPoint + GL.CAPTIONDEFAULTLENGTH
    } else {
      data.outPoint = inPoint + (draggedClip.clips.outPoint - draggedClip.clips.inPoint)
    }
  } else {
    if (draggedClip.clips.outPoint === -1) {
      console.log('从列表拖动的时候给个默认时长', draggedClip, inPoint)
      data.outPoint = data.inPoint + GL.CAPTIONDEFAULTLENGTH
    } else {
      data.outPoint = inPoint + (draggedClip.clips.outPoint - draggedClip.clips.inPoint)
    }
  }
  return data
}

export function deepCopy (obj) {
  return cloneDeepWith(obj, (value, key) => {
    if (key === 'raw') return value
  })
}

// 检查时间线上的相连的clip  用于在转场拖动时展示
let adjacentClip = []

export function setAdjacentClip (items) {
  adjacentClip = []
  let start = getTrackStartIndexInItems(items, GL.VIDEOTRACK)
  let last = getTrackLastIndexInItems(items, GL.VIDEOTRACK)
  console.log('start', start, 'last', last, 'item', items)
  for (let i = start; i < last + 1; i++) {
    for (let j = 0; j < items[i].clips.length; j++) {
      let nowClip = items[i].clips[j]
      if (j !== items[i].clips.length - 1) {
        let nextClip = items[i].clips[j + 1]
        console.log('nowClip', nowClip, nextClip, i, j, last - 1)
        if (nowClip.outPoint === nextClip.inPoint) {
          let index = {
            trackIndex: i,
            clipIndex: j
          }
          adjacentClip.push(index)
        }
      }
    }
  }
  console.log('getAdjacentClipKey111111', adjacentClip)
  EventBus.$emit(EventBusKey.getAdjacentClipKey, adjacentClip)
}

export function getAdjacentClip () {
  return adjacentClip
}

// 每次拖动转场时记录videoClip的已有的转场cover 用以判断松手时在哪个clip
let trantionCovers = []

export function setTransitionCovers () {
  let transitionCover = document.getElementsByClassName('transitionCover')
  // console.log('setTransitionCovers', transitionCover)
  trantionCovers.length = 0
  for (let i = 0; i < transitionCover.length; i++) {
    let div = transitionCover[i]
    // console.log('setTransitionCovers', div, div.classList, div.classList.contains('right'), div.style.display !== 'none')
    if (div.style.display !== 'none' && div.classList.contains('right')) {
      let id = div.id
      let trackIndex = parseInt(id.substring(0, id.indexOf(',')))
      let clipIndex = parseInt(id.substring(id.indexOf(',') + 1, id.length))
      // console.log('---------', trackIndex, clipIndex)
      let coverPosition = {
        left: div.getBoundingClientRect().left,
        right: div.getBoundingClientRect().left + 40,
        top: div.getBoundingClientRect().top,
        bottom: div.getBoundingClientRect().bottom,
        trackIndex: trackIndex,
        clipIndex: clipIndex
      }
      trantionCovers.push(coverPosition)
    }
  }
  // console.log('trantionCovers', trantionCovers)
}

export function checkTransitionCovers (x, y) {
  // console.log('checkTransitionCovers', x, y, trantionCovers)
  for (let i = 0; i < trantionCovers.length; i++) {
    if (x > trantionCovers[i].left && x < trantionCovers[i].right && y > trantionCovers[i].top && y < trantionCovers[i].bottom) {
      return trantionCovers[i]
    }
  }
}

export function getTrantionCovers () {
  return trantionCovers
}

function getImageSizeAndStep (clip) {
  let durtion = clip.orgDuration
    if (clip.videoType === 3) {
      durtion = clip.trimOut - clip.trimIn
    }
    let width = getWidthByDurationF(durtion, clip)
    let parentHeight = Utils.getTrackDOMHeight(clip.type)
    let h = parentHeight - 4
    let w = h
    let num = 1
    let step = 1
    let unitSecond = 1
  if (clip.type === GL.VIDEOCLIP) {
    // 计算视频比例
    let avFileInfo = TimelineUtils.nvStreamingContext().getAVFileInfo(clip.m3u8Path, 0)
    let fileWidth = avFileInfo.videoStreamInfo.width
    let fileHeight = avFileInfo.videoStreamInfo.height
    let rotation = avFileInfo.videoStreamInfo.rotation
    if (rotation === 1 || rotation === 3) {
      let a = fileWidth
      fileWidth = fileHeight
      fileHeight = a
    }
    let fileRatio = fileWidth / fileHeight
    w = Math.ceil(h * fileRatio)
    num = Math.ceil(width / w)

    unitSecond = getSecondDurationByWidth(w)
    let step = parseInt(clip.thumbnails.length / num)
    if (step < 1) {
      step = 1
    }
  }

  return {
    width: w,
    height: h,
    step: step,
    num: num,
    unitSecond: unitSecond
  }
}

function getTrackIndexByClip (clip = {}) {
  const { uuid, type } = clip
  const clipItemDom = document.getElementById(`${type},${uuid}`)
  if (clipItemDom) {
    const containerId = clipItemDom.parentNode.id
    if (containerId) {
      const arr = containerId.split(',')
      if (arr.length === 3) {
        return arr[1]
      }
    }
  }
  console.warn('没有获取到片段所在的轨道索引');
  return -1
}

/**
 * @param changeType 控制音频缩略图存在时，是重新生成，还是只设置显示位置px
 */
export function updateClipThumbnails (clip = {}) {
  const { outPoint, inPoint } = clip
  const trackIndex = getTrackIndexByClip(clip)
  const pixelPerMicrosecond = store.getters['timeline/pixelPerMicrosecond']
  const width = getWidthByDuration2(outPoint - inPoint, pixelPerMicrosecond)
  if (clip.type === 'video') {
    getImageByClip(clip, 'updateTrackItem', trackIndex)
  }
  if ((clip.type === 'video' || clip.type === 'audio') && width >= 10) {
    const clipContainerWidth = getWidthByDuration2(clip.outPoint - clip.inPoint, pixelPerMicrosecond)
    let audioWaveInstance = NvAudioWaveManager.instance.getAudioWave(clip.uuid)
    if (audioWaveInstance && clipContainerWidth === audioWaveInstance.clipContainerWidth) {
      NvAudioWaveManager.instance.notifyWidthChanged(clip, clipContainerWidth)
    } else {
      getAudioWaveByClip(clip, pixelPerMicrosecond)
    }
  }
}

export function getAudioWaveByClip (clip) {
  if (Utils.isEmpty(clip.leftChannelUrl)) {
    console.warn('Left channel url is empty. ')
    return
  }
  const trackIndex = getTrackIndexByClip(clip)
  if (trackIndex === -1 ) return
  const pixelPerMicrosecond = store.getters['timeline/pixelPerMicrosecond']
  const audioUrl = TimelineUtils.getAudioClipWaveUrl(clip)
  const clipItem = document.getElementById(clip.uuid ? `${clip.type},${clip.uuid}` : `${clip.type},${trackIndex},${clip.index},clipItem`)
  const clipContainerWidth = getWidthByDuration2(clip.outPoint - clip.inPoint, pixelPerMicrosecond)
  NvAudioWaveManager.instance.createAudioWave({
    container: clipItem,
    audioUrl,
    clipContainerHeight: Utils.getTrackDOMHeight(clip.type),
    clipContainerWidth,
    uuid: clip.uuid,
    clipTrimIn: clip.trimIn,
    clipTrimOut: clip.trimOut,
    clipDuration: clip.orgDuration,
    clip: clip
  })
}

export function getImageByClip (clip, action, trackIndex) {
  if (clip.type !== GL.VIDEOCLIP) return
  const ws = getImageSizeAndStep(clip)
  if (ws.width === undefined) return
  let shouldHaveThumbnail = false
  let images = []
  if (clip.videoType === 3 && !clip.isDir) { // 图片
    shouldHaveThumbnail = true
    let url
    try {
      url = Utils.getThumbnailUrl(clip, 0)
    } catch (error) {
      const reg = new RegExp(Utils.getCOSHost())
      if (!reg.test(clip.path)) {
        url = clip.path.replace(/(.*.com)/, Utils.getCOSHost())
      } else {
        url = clip.path
      }
    }
    if (store.state.timeline.isThumbnailAll) {
      images = [`url(${url}) repeat 0 0/auto 100%`]
    } else {
      images = [`url(${url}) no-repeat 0 0/auto 100%`]
    }
  } else if (clip.videoType === 1 || (clip.videoType === 3 && clip.isDir)) { // 视频
    shouldHaveThumbnail = true
    const view = document.getElementById('trackContainer')
    if (store.state.timeline.isThumbnailAll) {
      images = buildVideoThumbnailImages(
        clip,
        ws,
        getWidthByDuration2(clip.inPoint, GL.getTLPXEveryTime()),
        view.offsetWidth,
        view.scrollLeft,
        getWidthByDuration2(clip.outPoint - clip.inPoint, GL.getTLPXEveryTime())
      )
    } else {
      const currentImg = `url(${Utils.getThumbnailUrl(clip, 0)})`
      if (currentImg) {
        const { width } = ws
        const position = `0px 0px/${width}px 100%`
        images = [`${currentImg} no-repeat ${position}`]
      }
    }
  }
  if (shouldHaveThumbnail) {
    if (action === 'updateTrackItem') {
      let clipDiv = document.getElementById('video' + ',' + clip.uuid)
      if (!clipDiv) {
        clipDiv = document.getElementById('video' + ',' + trackIndex + ',' + clip.index + ',clipItem')
      }
      if (clipDiv) {
        clipDiv.style.background = images.join(',')
        clipDiv.style.backgroundColor = 'black !important;'
      }
    } else if (action === 'drag') {
      const dom = document.getElementById('draggingDiv')
      if (!dom) return
      const thumbnailsView = dom.children[0]
      thumbnailsView.style.background = images.join(',')
      thumbnailsView.style.backgroundColor = 'black !important;'
    }
  }
}

/**
 * 计算需要显示的缩略图列表
 * @param {object} clip videoClip
 * @param {*} ws 保护图片信息， width: 图片的宽度px, unitSecond：一张图片占用的时间(s, 1倍速)
 * @param {Number} clipLeft clip的left px
 * @param {*} offsetWidth 容器的宽度 px
 * @param {*} scrollLeft 滚动条left px
 * @param {*} clipWidth clip宽度 px
 */
 function buildVideoThumbnailImages (clip, ws, clipLeft, offsetWidth, scrollLeft, clipWidth) {
  offsetWidth = Math.min(offsetWidth, clipWidth)
  const buffer = 5 // 左右缓冲图片个数
  let { width, unitSecond } = ws
  unitSecond *= clip.speed
  let dividend
  if (clip.thumbnailHost) {
    dividend = clip.thumbnailStep
  } else if (clip.thumbnails.length > 1) {
    dividend = clip.thumbnails[1].time - clip.thumbnails[0].time
  } else {
    dividend = 1000000
  }
  let startLeftPx = Math.max(parseInt((scrollLeft - clipLeft) / width - buffer) * width, 0) // 渲染的第一张图片到clip左侧的距离px
  let startShowIndex = startLeftPx / width // 渲染的第一张图片在所有要渲染图片中的下标
  let startLeftTime = startShowIndex * unitSecond * 1000000 + clip.trimIn // 渲染的第一张图片到clip左侧的距离us
  let maxImgCount = Math.ceil(offsetWidth / width) * 2 // 最多渲染的图片个数
  let startIndex = Math.round(startLeftTime / dividend)
  let res = []
  let currentImg
  for (let i = startIndex;;) {
    if (clip.thumbnailHost) {
      currentImg = `url(${clip.thumbnailHost}-${buildFormatedThumbnailNumber(i * clip.thumbnailStep / 1000)}.${clip.thumbnailType})`
    } else if (clip.thumbnailBaseUrl && i <= clip.thumbnails.length - 1) {
      currentImg = `url(${clip.thumbnailBaseUrl.replace('#ID#', clip.thumbnails[i].url)})`
    }
    // 数组里已经有的缩略图长度
    const left = res.length * width + startLeftPx
    if (left > clipWidth) break
    const position = `${res.length * width + startLeftPx}px 0px/${width}px 100%`
    res.push(`${currentImg} no-repeat ${position}`)
    startLeftTime += unitSecond * 1000000
    i = Math.floor(startLeftTime / dividend)
    if (res.length >= maxImgCount) break
  }
  if (res.length < maxImgCount) {
    const resLength = res.length
    for (let i = resLength; i < maxImgCount - resLength; i++) {
      let position = `${res.length * width + startLeftPx}px 0px/${width}px 100%`
      if (currentImg) {
        res.push(`${currentImg} no-repeat ${position}`)
      }
    }
  }
  return res
}

function getWidthByDurationF (duration, clip) {
  if (clip !== undefined && (clip.type === GL.VIDEOCLIP || clip.type === GL.AUDIOCLIP)) {
    duration = duration / clip.speed
  }
  return getWidthByDuration2(duration, GL.getTLPXEveryTime())
}

export function getImagePosition (clip) {
  if (clip.type === GL.VIDEOCLIP || clip.type === GL.AUDIOCLIP) {
    let position = ''
    if (clip.videoType === 3) {
      // 图片
      position += '0px' + ' 0'
    } else {
      let wh = getImageSizeAndStep(clip)
      let length = clip.type === GL.AUDIOCLIP ? 1 : wh.num
      let w = wh.width
      let h = wh.height
      let defaultL = 0
      let defaultT = 0
      let trimW = getWidthByDurationF(clip.trimIn, clip)
      for (let i = 0; i < length; i++) {
        position += w * i - trimW + defaultL + 'px' + ' ' + defaultT + 'px'
        if (i !== length - 1) {
          position += ','
        }
      }
    }
    return position
  }
  return ''
}

function getBackgroundWH (clip) {
  let width = getWidthByDurationF(clip.orgDuration, clip)
  let length = clip.type === GL.AUDIOCLIP ? 1 : clip.thumbnails.length
  let w = width / length
  let defaultW = w
  let parentHeight = Utils.getTrackDOMHeight(clip.type)
  let h = parentHeight - 4
  let defaultH = h

  if (clip.videoType === 3) {
    w = h
    return {
      w: w,
      h: h,
      defaultW: defaultW,
      defaultH: defaultH
    }
  }
  // 计算宽高
  // let defaultRatio = w/h
  // let avFileInfo= TimelineUtils.nvStreamingContext().getAVFileInfo(clip.m3u8Path, 0)
  // let fileWidth = avFileInfo.videoStreamInfo.width
  // let fileHeight = avFileInfo.videoStreamInfo.height
  // let rotation = avFileInfo.videoStreamInfo.rotation
  // if ( rotation === 1 || rotation === 3) {
  //   let a = fileWidth
  //   fileWidth = fileHeight
  //   fileHeight = a
  // }
  // let fileRatio =fileWidth / fileHeight
  // if (defaultRatio > fileRatio) {
  //   h = w / fileRatio
  // } else {
  //   w = h * fileRatio
  // }
  return {
    w: w,
    h: h,
    defaultW: defaultW,
    defaultH: defaultH
  }
}

export function getBackgroundSize (clip) {
  if (clip.type === GL.VIDEOCLIP || clip.type === GL.AUDIOCLIP) {
    let wh = getImageSizeAndStep(clip)
    let w = wh.width
    let h = wh.height
    w += 'px'
    h += 'px'
    // console.log('最终的宽高', w, h)
    return w + ' ' + h
  }
}

/**
 * 插入数据
 * @param checkClipDatas 轨道的clips
 * @param checkClip 要插入的clip信息
 * @param addNewClip true是插入 false是只分离，不插入新数据
 * @returns {{data: (*|Array), hasSplitClip: boolean, index: number, needAddClip}}
 */
export function checkClipInsert(checkClipDatas, checkClip, addNewClip) {
  // console.log('checkClipInsert', checkClipDatas, checkClip, addNewClip)

  // for (let i = 0; i < checkClipDatas.length; i++) {
  //   console.log('checkClipInsert', i, checkClipDatas[i].inPoint, checkClipDatas[i].outPoint)
  // }
  let afterInsertClip = insertClip(checkClipDatas, checkClip, addNewClip)
  // for (let i = 0; i < afterInsertClip.data.length; i++) {
  //   console.log('afterInsertClip1111', i, afterInsertClip.data[i].inPoint, afterInsertClip.data[i].outPoint)
  // }
  let newClipData = afterInsertClip.data
  if (addNewClip) {
    newClipData.push(checkClip)
    afterInsertClip.splitAndInsertClip.push(checkClip)
  }
  afterInsertClip.data = sortClip(newClipData)

  afterInsertClip.splitAndInsertClip = sortClip(afterInsertClip.splitAndInsertClip)

  // for (let i = 0; i < afterInsertClip.data.length; i++) {
  //   console.log('afterInsertClip222', i,
  //     afterInsertClip.data[i].inPoint,
  //     afterInsertClip.data[i].outPoint
  //   )
  // }
  return afterInsertClip
}
function insertClip (checkClipDatas, checkClip, addNewClip) {
  let inPoint = 0
  let outPoint = 0
  let newClipData = []
  let duration = checkClip.outPoint - checkClip.inPoint
  let find = false
  let hasSplitClip = false
  let insertIndex = 0
  const splitAndInsertClip = []
  for (let j = 0; j < checkClipDatas.length; j++) {
    inPoint = checkClipDatas[j].inPoint
    outPoint = checkClipDatas[j].outPoint
    // console.log(' checkClipInsert ' + inPoint + ' outPoint: ' + outPoint + '  checkClip.inPoint: ' + checkClip.inPoint + '  checkClip.outPoint: ' + checkClip.outPoint)
    if (outPoint <= checkClip.inPoint) { // 完全在该clip前面
      // console.log('完全在该clip前面')
      newClipData.push(checkClipDatas[j])
    } else if (checkClip.inPoint > inPoint) { // 找到了冲突点
      if (!find) {
        // 检测是否分割
        // console.log('视频被分割')
        hasSplitClip = true
        let newClip = deepCopy(checkClipDatas[j])
        let cutDuration = outPoint - checkClip.inPoint
        checkClipDatas[j].outPoint = checkClip.inPoint
        checkClipDatas[j].trimOut -= cutDuration
        // console.log('被分割视频前面的那个clip：', checkClipDatas[j].inPoint,checkClipDatas[j].outPoint,checkClipDatas[j].trimOut)
        splitAndInsertClip.push(checkClipDatas[j])
        newClipData.push(checkClipDatas[j])
        insertIndex = j+1
        newClip.uuid = TimelineUtils.uuid()
        newClip.inPoint = checkClip.outPoint
        newClip.outPoint = cutDuration + newClip.inPoint
        newClip.trimIn = checkClipDatas[j].trimIn + (checkClipDatas[j].outPoint - checkClipDatas[j].inPoint)
        const diff = (newClip.inPoint - checkClipDatas[j].inPoint) - (checkClip.outPoint - checkClip.inPoint)
        const frames = getKeyFramesByClip(newClip)
        frames.forEach(frame => {
          frame.time = Number(frame.time) - diff
        })
        if (newClip.videoFxs) {
          let propertyFx = newClip.videoFxs.find(fx => fx.type === 'property')
          if (propertyFx) {
            let package2Id = propertyFx.isPostOutAnimation ? 'Post Package2 Id' : 'Package2 Id'
            if (propertyFx.animationType) {
              for (let i = 0; i < propertyFx.params.length; i++) {
                let param = propertyFx.params[i]
                if (param.key === 'Package Effect In' || param.key === 'Package Effect Out') param.value -= diff
              }
            } else {
              const paramPackage2Id = propertyFx.params.find(item => item.key === package2Id)
              if (paramPackage2Id && paramPackage2Id.value) {
                for (let i = 0; i < propertyFx.params.length; i++) {
                  let param = propertyFx.params[i]
                  if (param.key === 'Package2 Effect In' || param.key === 'Package2 Effect Out') param.value -= diff
                }
              }
            }
          }
          refreshVideoPropertyFxWhenMouseUp(newClip)
        }
        newClipData.push(newClip)
        splitAndInsertClip.push(newClip)
      }
    } else {
      // console.log('后移')
      find = true
      checkClipDatas[j].inPoint += duration
      checkClipDatas[j].outPoint += duration
      newClipData.push(checkClipDatas[j])
    }
  }
  return {
    data:newClipData,
    hasSplitClip: hasSplitClip,
    index: insertIndex,
    needAddClip: addNewClip,
    splitAndInsertClip
  }
}
export function sortClip (newClipData) {
  newClipData = bSort(newClipData)
  for (let j = 0; j < newClipData.length; j++) {
    newClipData[j].index = j
  }
  return newClipData
}
export function checkArrayClipInsert (checkClipDatas, checkClip,addNewClip, needAddClips) {
  let afterInsertClip = insertClip(checkClipDatas, checkClip,addNewClip)
  // console.log('checkArrayClipInsert1111',afterInsertClip, checkClipDatas, checkClip,addNewClip,needAddClips)
  let newClipData = afterInsertClip.data
  // for (let i = 0; i < newClipData.length; i++) {
  //   console.log('第一次插入',i, newClipData[i].inPoint, newClipData[i].outPoint)
  // }
  if (addNewClip) {
    for (let i = 0; i < needAddClips.length; i++) {
      // console.log('needAddClips', needAddClips[i].raw, needAddClips[i].inPoint)
      newClipData.push(needAddClips[i])
    }
  }
  afterInsertClip.data = sortClip(newClipData)
  // console.log('checkArrayClipInsert2222',afterInsertClip)
  // for (let i = 0; i < newClipData.length; i++) {
  //   console.log('第二次插入',i, newClipData[i].inPoint, newClipData[i].outPoint)
  // }
  return afterInsertClip
}

// 多选， 计算选中的转场
export function updateAllSelectedTransitions (allSelectData) {
  return allSelectData.map(selectItem => {
    const {clips, track} = selectItem
    const transitions = (track.transitions || []).filter(transition => {
      return clips.some(clip => clip.index === transition.index) && clips.some(clip => clip.index - 1 === transition.index)
    })
    selectItem.transitions = transitions
    return {
      ...selectItem,
      transitions
    }
  })
}
