/**
 * NOTE：因为不需要与素材中心进行跳转，所以不用存到cookie
 */
const TokenKey = 'Authorization'

export function getToken () {
  return localStorage[TokenKey]
}

export function setToken (token) {
  localStorage[TokenKey] = token
}

export function removeToken() {
  localStorage.removeItem(TokenKey)
}
