import { get, post, delet } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {

  job_list: ({ ...props }, cancelToken) => {
    if (!process.env.PERM_LIST.b_startPageNumberZero && process.env.NODE_ENV !== 'development') {
      props.page++
    }
    return get({
      url: NvNetwork.data().webApi_job_list,
      headers: {
        cancelToken,
        headers: {
          'Cache-Control': 'no-cache'
        },
        params: { ...props }
      }
    })
  },

  job_info: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_job_info,
      data,
      headers: {
        params: { ...props }
      }
    }),

  job_delete: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_job_delete,
      data,
      headers: {
        params: { ...props }
      }
    }),

  job_retry: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_job_retry,
      data,
      headers: {
        params: { ...props }
      }
    }),

  job_transcode: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_job_transcode,
      data,
      headers: {
        params: { ...props }
      }
    }),
}
