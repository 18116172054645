import { getToken, setToken, removeToken } from '@/utils/auth'
// 注意此时还没有vue实例
import api from '@/http'
const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: {
      authInfo: {}
    },
    thirdPartInfo: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setToken (state, payload) {
      state.token = payload
    },
    setUserInfo (state, payload) {
      state.userInfo = { authInfo: {}, ...payload }
    },
    setThirdPartInfo (state, info) {
      state.thirdPartInfo = [...info]
    }
  },
  actions: {
    // user login
    login ({ commit }, userInfo) {
      const { username, password, isSubAccount = false } = userInfo
      return new Promise((resolve, reject) => {
        api.user.login({
          loginName: username.trim(),
          password,
          appId: 'e8b0d33e687d45638ded3a46cadac995',
          isSubAccount
        }).then(resp => {
          commit('setToken', resp.token)
          setToken(resp.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // user logout
    logout ({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          removeToken() // must remove  token  first
          commit('resetState')
          sessionStorage.clear()
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },

    // remove token
    resetToken ({ commit }) {
      return new Promise(resolve => {
        removeToken() // must remove  token  first
        commit('resetState')
        resolve()
      })
    },

    async getUserInfo ({ commit }) {
      let userInfo = {}
      if (process.env.PERM_LIST.b_useUserInfoApi) {
        userInfo = await api.user.userInfo()
      }
      commit('setUserInfo', userInfo)
    }
  }
}
