import NvAudioWave from './NvAudioWave'
const EventEmitter = require('events')
let instance = null

export default class NvAudioWaveManager extends EventEmitter {
  audioWaves = new Map();

  constructor () {
    super()

    if (!instance) {
      instance = this
    }
    return instance
  }

  static get instance () {
    if (!instance) {
      instance = new NvAudioWaveManager()
    }
    return instance
  }

  createAudioWave (params) {
    let uuid = params.uuid
    if (uuid == undefined) {
      console.warn('Failed: audio clip uuid not exist.')
      return
    }
    if (this.audioWaves.has(uuid)) {
      this.audioWaves.delete(uuid)
    }
    let audioWave = new NvAudioWave(params)
    this.audioWaves.set(uuid, audioWave)
  }

  getAudioWave (uuid) {
    if (this.audioWaves.has(uuid)) {
      return this.audioWaves.get(uuid)
    } else {
      // console.warn('Audio wave does not exist.')
      return null
    }
  }

  notifyWidthChanged (clip, width, changeType) {
    let audioWave = this.getAudioWave(clip.uuid)
    if (audioWave == null) {
      // console.warn('Audio wave not found.')
      return
    }
    audioWave.notifyWidthChanged(clip, width, changeType)
  }
}
