import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    resWidth: 0,
    resHeight: 0,
    // 时间线开始时间
    startTimeFromM3u8: -1,
    ingestTostartFromM3u8: -1,
    // 用户选择的开始时间
    startTime: -1,
    // 收录开始时间
    ingestTime: -1,
    isM3u8Updating: true,
    clipList: [],
    captionList: [],
    atotUrl: '',
    incrementAtot: null,
    nextIncrementAtotUrl: '', // 语音转文字增量下一个url（暂停继续用）
    diffAtot: {},
    lastIndexWhenNotHit: -1,
    videoSpeechEnable: true, // true：老规则（websocket），false: 新规则（轮询）
    audioToTextPollTimerId: 0, // 轮询计时器 和socket连接断开保持同步
    firstEXTINFDuration: -1
  },
  mutations: {
    updateFirstEXTINFDuration (state, payload) {
      state.firstEXTINFDuration = payload
    },
    updateAudioToTextPollTimerId (state, payload) {
      state.audioToTextPollTimerId = payload
    },
    updateNextIncrementAtotUrl (state, payload) {
      state.nextIncrementAtotUrl = payload
    },
    updateVideoSpeechEnable (state, payload) {
      state.videoSpeechEnable = payload
    },
    updateLastIndexWhenNotHit (state, payload) {
      state.lastIndexWhenNotHit = payload
    },
    updateDiffAtot (state, payload) {
      state.diffAtot = payload
    },
    deleteDiffAtotById (state, payload) {
      delete state.diffAtot[payload]
    },
    updateDiffAtotById (state, payload) {
      const { id } = payload
      state.diffAtot[id] = payload
    },
    // 语转文增量
    addNewAtot (state, payload) {
      state.incrementAtot = payload
    },
    updateAtotUrl (state, payload) {
      state.atotUrl = payload
    },
    toggleM3u8Updating (state, payload) {
      state.isM3u8Updating = !state.isM3u8Updating
      if (!state.isM3u8Updating) {
        clearTimeout(state.audioToTextPollTimerId)
        console.info('m3u8已停止更新')
      }
    },
    updateIngestTostartFromM3u8 (state, payload) {
      state.ingestTostartFromM3u8 = payload
    },
    updateStartTimeFromM3u8 (state, payload) {
      state.startTimeFromM3u8 = payload
    },
    updateStartTime (state, payload) {
      state.startTime = payload
    },
    updateIngestTime (state, payload) {
      state.ingestTime = payload
    },
    updateClipHW (state, payload) {
      const { resWidth, resHeight } = payload
      state.resWidth = resWidth
      state.resHeight = resHeight
    },
    updateClipBeforePublish (state) {
      state.clipList.map(item => {
        if (item.selected) {
          Vue.set(item, 'published', true)
          item.selected = false
        }
      })
    },
    updateClipWithCheckAll (state, payload) {
      state.clipList.forEach(clip => {
        if (!clip.published && clip.status === 'finish') {
          clip.selected = payload
        }
      })
    },
    updateClipList (state, payload) {
      state.clipList = payload
    },
    updateItemByIndex (state, payload = {}) {
      const { index, value } = payload
      Vue.set(state.clipList, index, value)
    },
    addItem (state, payload) {
      state.clipList.push(payload)
    },
    deleteItemByIndex (state, payload) {
      state.clipList.splice(payload, 1)
    },
    updateCaptionList (state, payload) {
      state.captionList = payload
    },
  }
}
