import router from './router'
import store from './store'
import _enum from './utils/enum'
import { getToken, setToken } from '@/utils/auth' // get token from cookie
import { Message } from 'element-ui'
import Vue from 'vue'
import axios from 'axios'

// no redirect whitelist
const whiteList = [window.$nvsConfig.backPath]

if (process.env.PERM_LIST.p_login) {
  whiteList.push(...[
    '/Login',
    '/Register',
    '/GetBackPassword',
  ])
}

if (process.env.CONFIG_NAME === 'smg') {
  router.beforeEach(async (to, from, next) => {
    try {
      if (process.env.NODE_ENV !== 'development') {
        const response = await axios.get('/static/versionfile.json?_=' + Math.random())
        if (200 === response.status && process.env.VERSION !== response.data.version) {
          const message = "系统版本有更新，点击确认加载最新，或按【CTRL + F5】！"
          Vue.prototype.$alert(message, '系统提示', {
            confirmButtonText: '确定',
            callback () {
              window.location.reload(true)
            }
          })
          return
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      // determine whether the user has logged in
      const { token, projectId, projectName, resolution } = to.query
      // projectId 打开旧工程使用
      // projectName/resolution 创建新工程使用
      if (token) {
        sessionStorage.nvseditlogintoken = token
        setToken(token)
      }
      sessionStorage.nvsprojectid = projectId || ''
      if (projectName) sessionStorage.nvsprojectname = projectName
      if (resolution) sessionStorage.nvsResolution = resolution
      let fromUrl = document.referrer
      if (fromUrl) {
        fromUrl = fromUrl.split('/')[2]
      }
      const { domainKeyMap } = _enum
      if (location.host === fromUrl && sessionStorage.referrer) {  // 解决多租户刷新问题。云剪辑刷新后reffer会变成自己的url
        console.warn('来自 自己')
        fromUrl = sessionStorage.referrer
      }
      if (process.env.NODE_ENV === 'development' || Object.keys(domainKeyMap).includes(fromUrl)) {
        sessionStorage.referrer = fromUrl
        console.warn('来自：', fromUrl)
        const fromPath = fromUrl.replace(/^http:\/\/|https:\/\//, '').replace(/\/+$/, '')
        // fromPath 格式为 www.baidu.com  不带https和末尾的/
        const url = location.protocol + '//' + fromPath + '/'
        const oldHost = typeof window.$nvsConfig.apiHost === 'string' ? window.$nvsConfig.apiHost : window.$nvsConfig.apiHost.material
        window.$nvsConfig.apiHost = {
          sts: url, // 获取云服务上传凭证
          job: url, // 任务相关
          user: url, // 用户相关
          resource: url, // 资源相关
          audio: url, // 视频同步
          video: url, // 成片相关
          material: oldHost, // 素材相关
          project: url, // 工程相关
          recognition: url, // 语音转写
          upload: url // 上传相关， upload/config,upload/info
        }
        window.$nvsConfig.backPath = url + 'index.html#/cloudEdit'
        window.$nvsConfig.returnPath = url + 'index.html#/cloudEdit/edit'
        window.$nvsConfig.materialUrl = url + 'index.html#/myMaterial/materialList'
        // window.$nvsConfig.liveApiHost = url
        // window.$nvsConfig.liveWsHost = (location.protocol.includes('s') ? 'wss://' : 'ws://') + fromPath + '/'
        console.log('路由进入前', window.$nvsConfig.apiHost)
      } else {
        console.error('非法请求! 请在快编列表打开项目工程文件')
        console.log('支持的来源:', Object.keys(domainKeyMap), '来自:', fromUrl)
        Message({
          type: 'error',
          message: '非法请求! 请在快编列表打开项目工程文件',
          duration: 0,
          showClose: true
        })
        return
        // next(false)
      }
      // 要在从host确认好url之后，才能调用getUserInfo，否则apihost不对
      await store.dispatch('user/getUserInfo')
      if (!sessionStorage.nvseditlogintoken) {
        if (window.$nvsConfig && window.$nvsConfig.backPath) {
          location.href = window.$nvsConfig.backPath
          // next({ path: window.$nvsConfig.backPath })
        } else {
          next()
        }
      } else {
        next()
      }
    }
  })
} else if (process.env.CONFIG_NAME === 'pku') {
  router.beforeEach((to, from, next) => {
    if(to.path === '/Login' || to.path === '/login') {
      window.location.href = 'https://resource.pku.edu.cn/'
    } else {
      next()
    }
  })
} else {
  router.beforeEach(async (to, from, next) => {
    try {
      if (process.env.NODE_ENV !== 'development') {
        const url = process.env.CONTEXT ? `/${process.env.CONTEXT}/` : '/'
        const response = await axios.get(url + 'static/versionfile.json?_=' + Math.random())
        if (200 === response.status && process.env.VERSION !== response.data.version) {
          const message = "系统版本有更新，点击确认加载最新，或按【CTRL + F5】！"
          Vue.prototype.$alert(message, '系统提示', {
            confirmButtonText: '确定',
            callback () {
              window.location.reload(true)
            }
          })
          return
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      /* has no token */
      const { token, projectName, resolution, userId, tenantId, apiVersion, projectId, nvsWidth, nvsHeight } = to.query
      // projectName/resolution 创建新工程使用
      if (token) setToken(token)
      if (projectName) sessionStorage.nvsprojectname = projectName
      if (resolution) sessionStorage.nvsResolution = resolution
      userId && (localStorage.nvseditloginuserid = userId)
      tenantId && (localStorage.nvseditlogintenantid = tenantId)
      apiVersion && (localStorage.apiVersion = apiVersion)
      projectId && (localStorage.nvsprojectid = projectId)
      nvsWidth && (localStorage.nvsWidth = nvsWidth)
      nvsHeight && (localStorage.nvsHeight = nvsHeight)

      let referrerPass = true
      if (process.env.PERM_LIST.b_refererOn) {
        let fromUrl = document.referrer
        if (fromUrl) {
          fromUrl = fromUrl.split('/')[2]
        }
        const domainWhiteList = window.$nvsConfig.domainWhiteList || []
        if (location.host === fromUrl && sessionStorage.nvsReferrer) {
          console.warn('来自自己')
          fromUrl = sessionStorage.nvsReferrer
        } else if (process.env.NODE_ENV === 'development' || domainWhiteList.length === 0 || domainWhiteList.includes(fromUrl)) {
          sessionStorage.nvsReferrer = fromUrl
          console.warn('来自', fromUrl)
        } else {
          referrerPass = false
        }
      }
      if (!referrerPass) {
        console.error('禁止分享！')
        Message({
          type: 'error',
          message: '禁止分享！',
          duration: 0,
          showClose: true
        })
        return
      }
      // determine whether the user has logged in
      const hasToken = getToken()
      if (hasToken) {
        if (store.getters.userInfo.userId) {
          next()
        } else {
          try {
            await store.dispatch('user/getUserInfo')
            next()
          } catch (error) {
            // remove token and go to login page to re-login
            await store.dispatch('user/resetToken')
            if (window.$nvsConfig && window.$nvsConfig.backPath) {
              location.replace(window.$nvsConfig.backPath)
            }
          }
        }
      } else {

        if (whiteList.indexOf(to.path) !== -1) {
          // in the free login whitelist, go directly
          next()
        } else {
          sessionStorage.clear()
          localStorage.clear()
          // other pages that do not have permission to access are redirected to the login page.
          if (window.$nvsConfig && window.$nvsConfig.backPath) {
            // next({ path: window.$nvsConfig.backPath })
            location.href = window.$nvsConfig.backPath
          }
        }
      }
    }
  })
}

router.afterEach(() => {
})

router.onError(error => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    console.error('缓存未清理，需要重新加载...')
    router.replace(targetPath)
  }
})
