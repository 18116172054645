/* eslint-disable no-multi-spaces */
export default {
  listSortType: {
    time: 0,
    status: 1
  },
  listOrderType: {
    desc: 'desc',
    asc: 'asc'
  },
  materialType: {
    video: 1,
    audio: 2,
    picture: 3,
    folder: 8
  },
  materialFrom: {
    upload: 1,
    materialCenter: 2
  },
  uploadStatus: {
    waiting: 1,
    uploading: 2,
    uploadSuccess: 3,
    uploadFail: 4
  },
  jobStatus: {
    init: 0,
    done: 1,
    fail: 2,
    cancel: 3,
    timeout: 4,
    doing: 5
  },
  reverseStatus: {
    doing: 0,
    done: 1,
    not: 2,
  },
  splitCategory: {
    clip: 1,
    people: 2,
    voice: 3,
    word: 4
  },
  projectType: {
    normal: 1,
    live: 2,
    template: 3,
    split: 4,
    textVideo: 5,
    meiying: 6,
    interact: 7,
    cover: 8
  },
  jobType: {
    upload: 0, // 转码任务
    compile: 1, // 合成任务
    audioToText: 2, // 识别任务
    projectTranscode: 3, // 工程转码任务
    audioCompare: 4, // 音频对比
    splitWord: 5, // 分词
    splitVideo: 6, // 拆条
    tag: 9,  // 智能标签,
    compileWithTranscode: 11, // 合成并转码
    alphaTranscode: 12, // 带alpha通道得转码
  },
  resolutions: {
    '16v9': 1,
    '1v1': 2,
    '9v16': 4,
    '4v3': 8,
    '3v4': 16,
    '18v9': 32,
    '9v18': 64,
    '21v9': 512,
    '9v21': 1024
  },
  uploadType: {
    upload: 0,
    transcode: 1
  },
  ossType: {
    tencent: 1,
    amazon: 2,
    ali: 3,
    baidu: 4,
    local: 5,
    huawei: 6,
    ksyun: 7
  },
  captionFormat: {
    txt: 'txt',
    srt: 'srt',
    csv: 'csv'
  },
  uploadModule: {
    prProject: 'pr_project',
    bsProject: 'bs_project',
    appProject: 'app_project',
    video: 'resource_video',
    image: 'resource_image',
    audio: 'resource_audio',
    template: 'material_template',
    font: 'material_font',
    temp: 'temp', // 存放临时文件
    multi_project: 'multi_project' // 多端互通工程存放
  },
  thirdParts: {
    weibo: 'weibo', // 新浪微博
    wechart: 'wechart', // 微信视频号
    kuaishou: 'kuaishou', // 快手
    xinhua: 'xinhua', // 新华网
    douyin: 'douyin' // 抖音
  },
  pieceBorder: {
    media: 0,
    monitor: 1,
    timeline: 2
  },
  coarseJobStatus: {
    waiting: 0,
    compileDoing: 1,
    compileDone: 2,
    compileFail: 3,
    transcodeDoing: 4,
    transcodeDone: 5,
    transcodeFail: 6
  },
  materialAuthType: {
    public: 'public',
    authed: 'authed',
    private: 'private'
  },
  // 与后端对应
  mType: {
    theme: 1,
    videofx: 2,
    captionstyle: 3,
    animatedsticker: 4,
    videotransition: 5,
    font: 6,
    music: 7,
    particle: 9,
    // faceSticker: 10,
    arscene: 14,
    compoundCaption: 15,
    photoAlbum: 16,
    mimo: 17,
    vlog: 18,
    template: 19,
    animationVideofx: 54, // 动画
    backgroundImage: 8,
    animationCaption: 11,
    effectfx: 10,
    animationSticker: 12
  },
  mediaVideoFxType: {
    filter: 2,
    animation: 54,
    effect: 10
  },
  particleType: {
    filter: 'filter', // 滤镜
    graffiti: 'graffiti' // 涂鸦
  },
  animationType: {
    inAnimation: 51,
    outAnimation: 52,
    loopAnimation: 53
  },
  captionAnimationType: {
    inAnimation: 58,
    outAnimation: 59,
    animation: 60
  },
  stickerAnimationType: {
    inAnimation: 61,
    outAnimation: 62,
    animation: 63
  },
  // 对应字幕的categoryId
  captionType: {
    basic: 35,
    libretto: 38,
    scroll: 41,
    free: 40,
    frame: 39,
    char: 37,
    // stretch: 7
    // compound: 8
    renderer: 64,
    context: 65
  },
  templateType: {
    standard: 1,
    selfAdaption: 2,
    aeConversion: 3
  },
  materialTypeToRatioMap: {
    theme: 'verticalTheme',
    videofx: 'square',
    captionstyle: 'square',
    captionrenderer: 'square',
    captioncontext: 'square',
    captioninanimation: 'square',
    captionoutanimation: 'square',
    captionanimation: 'square',
    animatedsticker: 'square',
    animatedstickerinanimation: 'square',
    animatedstickeroutanimation: 'square',
    animatedstickeranimation: 'square',
    videotransition: 'square',
    particle: 'square',
    arscene: 'square',
    compoundCaption: 'horizontal',
    photoAlbum: 'verticalAlbum',
    mimo: 'horizontalLarge',
    vlog: 'horizontalLarge',
    template: 'horizontalLarge',
    effectfx: 'square'
  },
  templateEditTab: {
    edit: 'edit'
  },
  templateReplaceType: {
    videoImage: 'videoImage',
    image: 'image',
    video: 'video'
  },
  templateReplaceTypeWithNumber: {
    videoImage: 0,
    video: 1,
    image: 2,
  },
  mediaEditTab: {
    basic: 'basic',
    speed: 'speed',
    filter: 'filter',
    effect: 'effect',
    colorCorrection: 'colorCorrection',
    audio: 'audio',
    keying: 'keyer',
    animation: 'animation',
    mask: 'mask',
    background: 'background'
  },
  captionEditTab: {
    basic: 'basic',
    renderer: 'renderer',
    context: 'context',
    animation: 'animation',
    align: "align",
  },
  stickerEditTab: {
    basic: 'basic',
    animation: 'animation'
  },
  TEXT_ALIGNMENT: {
    TEXT_ALIGNMENT_LEFT: 0,
    TEXT_ALIGNMENT_CENTER: 1,
    TEXT_ALIGNMENT_RIGHT: 2
  },
  systemFlag: {
    material: 0,
    bseditor: 1
  },
  // 特效
  FX_DESC: {
    TRANSFORM_2D: 'Transform 2D',
    COLOR_PROPERTY: 'Color Property',
    VIGNETTE: 'Vignette', // 暗角
    SHARPEN: 'Sharpen', // 锐度
    BASICIMAGEADJUST: 'BasicImageAdjust', // 图像调节
    TINT: 'Tint', // 着色
    DEFINITION: 'Definition', // 清晰度
    DENOISE: 'Denoise' // 噪点去除
  },
  // 特效参数
  FX_PARAM: {
    TRANS_X: 'Trans X', // 偏移
    TRANS_Y: 'Trans Y',
    SCALE_X: 'Scale X', // 缩放
    SCALE_Y: 'Scale Y',
    OPACITY: 'Opacity', // 透明度
    BRIGHTNESS: 'Brightness', // 亮度
    CONTRAST: 'Contrast', // 对比度
    SATURATION: 'Saturation', // 饱和度
    DEGREE: 'Degree', // 暗角
    AMOUNT: 'Amount', // 锐度
    EXPOSURE: 'Exposure', // 曝光-1~1
    HIGHLIGHT: 'Highlight', // 高光-1~1
    SHADOW: 'Shadow', // 阴影-1~1
    BLACKPOINT: 'Blackpoint', // 褪色-10~10
    VIBRANCE: 'Vibrance', // 自然饱和度-1~1
    TINT: 'Tint', // 色调-1~1
    TEMPERATURE: 'Temperature', // 色温-1~1
    INTENSITY: 'Intensity' // 清晰度0~1/噪点去除0~1
  },
  // 音频特效
  FX_AUDIO_DESC: {
    AUDIO_EQ: 'Audio EQ' // 音频降噪
  },
  // 音频特效参数
  FX_AUDIO_PARAM: {
    HERTZ_31: '1 Band Gain', //	-20.0
    HERTZ_40: '2 Band Gain', //	-20.0
    HERTZ_50: '3 Band Gain', //	-20.0
    HERTZ_63: '4 Band Gain', //	...
    HERTZ_80: '5 Band Gain', //
    HERTZ_100: '6 Band Gain', //
    HERTZ_125: '7 Band Gain', //
    HERTZ_160: '8 Band Gain', //
    HERTZ_200: '9 Band Gain', //
    HERTZ_250: '10 Band Gain', //	-14.7
    HERTZ_315: '11 Band Gain', //
    HERTZ_400: '12 Band Gain', //
    HERTZ_500: '13 Band Gain', //	-14.3
    HERTZ_630: '14 Band Gain', //
    HERTZ_800: '15 Band Gain', //
    HERTZ_1000: '16 Band Gain', // -11.8
    HERTZ_1250: '17 Band Gain', //
    HERTZ_1600: '18 Band Gain', //
    HERTZ_2000: '19 Band Gain', // -10.5
    HERTZ_2500: '20 Band Gain', //
    HERTZ_3200: '21 Band Gain', //
    HERTZ_4000: '22 Band Gain', // -17.7
    HERTZ_5000: '23 Band Gain', //
    HERTZ_6300: '24 Band Gain', //
    HERTZ_8000: '25 Band Gain', //
    HERTZ_10000: '26 Band Gain', //
    HERTZ_12500: '27 Band Gain', //
    HERTZ_16000: '28 Band Gain', //
    HERTZ_20000: '29 Band Gain', //
    HERTZ_25000: '30 Band Gain' //
  },
  videoTagTab: {
    envTag: '3',
    imageDef: '4',
    peopleTag: '2',
    objectTag: '1',
    specialDef: '5'
  },
  templateSearchType: {
    name: 0,
    tag: 1
  },
  resourceListType: {
    project: '0',
    own: '1',
    public: '2',
    split: '3'
  },
  domainKeyMap: {
    'testxnews.smgtech.net': '6MkPjmKemOxbAXJhCcvxDgrfZRWsRJ3E',
    'zzb-xnews.smgtech.net': 'KEhifHyNhlI4tT9DpbUIqbkdfoPdpIqO',
    'testradio.smgtech.net': 'juJbOCSlU5zdHzTOYhPS518a073cDtjz',
    'esports.smgtech.net': 'OyePkRul9aQYFeiQQFKxI4Uk7SkLz43r',
    'xnews.smgtech.net': '2kN939Vf0sVMBL0Nd6m9uBdgS7FSx6te',
    'eradio.smgtech.net': 'ID3HfoLfMUlxPocVgOF6A0F9xfxHfaZD',
    'xkgc.smgtech.net': 'lKuYsnZvdqbdrLcO9msACJh8pLrBn2AO'
  },
  channelType: {
    stereo: '0',
    left: '1',
    right: '2'
  },
  attachmentType: {
    replaceId: 'MSTemplate-ReplaceId',
    footageId: 'MSTemplate-FootageId',
    footageTags: 'MSTemplate-FootageTags',
    footageName: 'MSTemplate-FootageName',
    footageM3u8Name: 'MSTemplate-FootageM3U8Name',
    footageCanReplace: 'MSTemplate-FootageCanReplace',
    footageNeedReverse: 'MSTemplate-FootageNeedReverse',
    volumeType: 'MSTemplate-VolumeType',
    footageType: 'MSTemplate-FootageType',
    fxGroup: 'MSTemplate-FxGroup',
    fxSeekTime:'MSTemplate-BestSeekTime',
    clipRawWidth:'MSTemplate-ClipRawWidth',
    clipRawHeight:'MSTemplate-ClipRawHeight',
  },
  captionFormat: {
    txt: 'txt',
    srt: 'srt',
    csv: 'csv'
  },
  liveType: {
    tv: '1',
    dazhibo: '2'
  },
  // value与FS对应，key与indexeddb的key对应
  assetPackageType: {
    dat: 'dat',
    model: 'model',
    font: -2,
    m3u8: -1,
    videofx : 0,
    transition : 1,
    captionstyle : 2,
    animatedsticker : 3,
    theme : 4,
    capturescene : 5,
    ARScene : 6,
    compoundcaption : 7,
    captioncontext : 8,
    captionrenderer : 9,
    captionanimation : 10,
    captioninanimation : 11,
    captionoutanimation : 12,
    template: 13,
    animatedstickeranimation : 16,
    animatedstickerinanimation : 17,
    animatedstickeroutanimation : 18,
    project : 22,
  },
  backgroundFillType: {
    'Image File': 'image',
    'Blur': 'blur',
    'Color Solid': 'color'
  },
  letterSpacingType: {
    percentage: 0,
    absolute: 1
  },
  captionKFParamType: {
    scaleX: 'Caption ScaleX',
    scaleY: 'Caption ScaleY',
    transX: 'Caption TransX',
    transY: 'Caption TransY',
    rotation: 'Caption RotZ'
  },
  stickerKFParamType: {
    scale: 'Sticker Scale',
    transX: 'Sticker TransX',
    transY: 'Sticker TransY',
    rotation: 'Sticker RotZ'
  },
  fxKFParamType: {
    intensity: 'Filter Intensity'
  },
  multiProjectInternalSubType: {
    backgroundimage: 'backgroundimage',
    resources: 'resources'
  }
}
