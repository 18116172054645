import { post } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {
  // 截取m3u8
  coarse_interceptM3u8: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_coarse_intercept,
      data,
      headers: {
        params: { ...props }
      }
    }),
  // 合并m3u8
  coarse_concatM3u8: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_coarse_concat,
      data,
      headers: {
        params: { ...props },
        'Content-Type': 'mutilpart/form-data'
      }
    }),

  coarse_refreshAuthKey: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_coarse_refreshAuthKey,
      data,
      headers: {
        params: { ...props }
      }
    }),

  coarse_deleteCompile: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_coarse_deleteCompile,
      data,
      headers: {
        params: { ...props }
      }
    })
}
