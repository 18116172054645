import { get, post, delet } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {

  material_list: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_material_list,
      headers: {
        params: {
          ...props,
          noNeedAuth: true
        }
      }
    }),

  material_all: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_material_all,
      headers: {
        params: {
          ...props,
          noNeedAuth: true
        }
      }
    }),

  material_categories: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_material_categories,
      headers: {
        params: {
          ...props,
          noNeedAuth: true
        }
      }
    }),
  material_categories_multi: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_material_categories_multi,
      headers: {
        params: {
          ...props,
          noNeedAuth: true
        }
      }
    }),

  material_add: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_material_add,
      data,
      headers: {
        params: { ...props }
      }
    }),

  material_arrearage: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_material_arrearage,
      data,
      headers: {
        params: { ...props }
      }
    }),

  material_submit_material: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_material_submitMaterial,
      data,
      headers: {
        params: { ...props }
      }
    }),

  material_private_update: (data) =>
    post({
      url: NvNetwork.data().webApi_material_private_update,
      data
    }),

  material_private_list: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_material_private_list,
      headers: {
        params: { ...props }
      }
    }),
  ...(process.env.CONFIG_NAME === 'pku' ? {
    material_all: (data,{ ...props }) =>
      post({
        url: NvNetwork.data().webApi_material_all,
        data,
        headers: {
          params: {
            ...props,
            noNeedAuth: true
          }
        }
      })
    }:{})
}
