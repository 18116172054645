import api from '@/http'
import { saveAndInstallPackage } from '@/api/AssetsUtils'
import Global from '@/api/Global'
import Bus from '@/EventBus'
import EventBusKey from '@/api/EventBusKey'

// 存储后台字体，用于各个组件公用。并且记录字体是否安装
export default {
  namespaced: true,
  state: {
    fontNameObj: {},
    fonts: [],
    invalidAssetIds: [],
    defaultFontInstalling: false
  },
  mutations: {
    updateInvalidAssetIds (state, payload) {
      state.invalidAssetIds = payload
    },
    updateFontNameObj (state, payload = {}) {
      const { key, value } = payload
      state.fontNameObj[key] = value
    },
    setHadFonts (state, fonts) {
      state.fonts = fonts.map(item => ({
        ...item,
        hasInstalled: false
      }))
    },
    // 更新字体是否已经下载的状态
    changeFontStatus (state, font) {
      const f = state.fonts.find(item => item.stringValue === font)
      if (f) {
        f.hasInstalled = true
      }
    },
    // 全局标识默认字体加载状态
    updateDefaultFontInstalling (state, payload) {
      state.defaultFontInstalling = payload
    }
  },
  actions: {
    async saveAndInstallDefaultFonts ({ commit, state, rootState }) {
      try {
        const publicListResult = await api.material.material_list({
          type: Enum.mType.font,
          page: 0,
          pageSize: 40
        })
        // const privateListResult = await api.material.material_private_list({
        //   type: Enum.mType.font,
        //   page: 0,
        //   pageSize: 40
        // })
        const publicList = publicListResult.materialList
        // const privateList = privateListResult.elements
        const combinedList = [
          ...publicList,
          // ...privateList
        ]
        commit('setHadFonts', combinedList.filter(item => item.id !== 'NotoColorEmoji'))
        const DEFAULT_INDEX = 0
        commit('updateDefaultFontInstalling', true)
        let familyName = await saveAndInstallPackage(publicList[DEFAULT_INDEX])
        // 避免使用Noto Sans CJK JP
        if (familyName.indexOf('Noto Sans CJK') !== -1) {
          familyName = 'Noto Sans CJK SC'
        }
        // test
        // await new Promise(resolve => setTimeout(resolve, 15000))
        commit('updateDefaultFontInstalling', false)
        if (rootState.layout.loadingInstance) {
          rootState.layout.loadingInstance.close()
          commit('layout/updateLoadingInstance', null, { root: true })
        }
        const emojiFont = publicList.find(item => item.id === 'NotoColorEmoji')
        if (emojiFont) {
          await saveAndInstallPackage(emojiFont)
        }
        let familyNameFromStringValue = publicList[DEFAULT_INDEX].stringValue
        if (familyNameFromStringValue.split(' [')[0] !== familyName) {
          commit('updateFontNameObj', {
            key: familyNameFromStringValue,
            value: familyName
          })
        }
        commit('changeFontStatus', familyNameFromStringValue)
        if (state.fontNameObj.hasOwnProperty(familyNameFromStringValue)) {
          familyNameFromStringValue = state.fontNameObj[familyNameFromStringValue]
        }
        Global.setDefaultFontFamily(familyNameFromStringValue)
        Bus.$emit(EventBusKey.defaultFontHasInstalled)
      } catch (error) {
        console.error('字体初始化失败', error)
      }
    }
  }
}
